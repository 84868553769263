import React, {Component} from "react";
import {MicroPagesStates} from "./types/MicroPageTypes";
import {withRouter} from "../../hoc/withRouter";
import parse from 'html-react-parser';
import Empty from "antd/lib/empty";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Skeleton from "antd/lib/skeleton";
import Tag from "antd/lib/tag";
import moment from "moment";
import PageHeader from "../../component/header/PageHeader";
import {Breadcrumb, Col, Row} from "antd";
import FooterSection from "../../component/pageSection/FooterSection";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";

class MicroPage extends Component<any, MicroPagesStates> {
    private cookies = new Cookies();
    private readonly initState!: MicroPagesStates;

    constructor(props: any) {
        super(props);

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        const {match} = this.props;
        const microPageCode = match.params.micropagecode;
        document.title = `AmartaChery | Page | ${microPageCode}`;

        this.initState = {
            microPageCode: microPageCode,
            dataMicroPage: {},
            dataAnotherPage: [],
            fetchMicroPage: true,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
        }
        this.state = {...this.initState}
    }

    fetchDetailMicroPage = async () => {
        try {
            await trimitraCatalogServices.getDetailMicroPage({
                title: this.state.microPageCode
            }).then(async dataResp => {
                await this.promisedSetState({
                    fetchMicroPage: false,
                    dataMicroPage: dataResp.data.data,
                });
            });
        } catch (e) {
            this.setState({
                fetchMicroPage: false,
                dataMicroPage: null,
            });
        }
    }

    fetchMicroPage = async () => {
        const tag = this.state.dataMicroPage?.tags[0];
        try {
            await trimitraCatalogServices.getListMicroPage({
                tags: tag
            }).then(dataResp => {
                this.setState({
                    dataAnotherPage: dataResp.data.data,
                });
            });
        } catch (e) {
            this.setState({
                dataAnotherPage: []
            });
        }
    }

    async componentDidMount() {
        await this.fetchDetailMicroPage();
        await this.fetchMicroPage();
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={true}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta Chery</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.dataMicroPage?.title?.replace(/-/g, ' ')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row className={`mt-20`} style={{backgroundColor: "#ffffff"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '92vh', backgroundColor: "#fff"}}>
                            <div style={(this.state.fetchMicroPage) ? {padding: 30} : {display: 'none'}}>
                                <Skeleton active={true} avatar paragraph={{rows: 12}}/>
                            </div>
                            <div style={(!this.state.fetchMicroPage && this.state.dataMicroPage === null) ? {paddingTop: 20} : {display: 'none'}}>
                                <Empty description={`Artikel tidak ditemukan`}/>
                            </div>
                            <div style={(!this.state.fetchMicroPage && this.state.dataMicroPage !== null) ? {paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20} : {display: 'none'}}>
                                <h2 style={{textTransform: "capitalize"}}>{this.state.dataMicroPage?.title?.replace(/-/g, ' ')}</h2>
                                <i style={{fontSize: '75%', float: "right"}}>{moment(this.state.dataMicroPage?.create_time).format('dddd, MMMM Do YYYY, h:mm a')}</i>
                                <br/><br/>
                                <img alt={`imageamarta`} width={'100%'} src={this.state.dataMicroPage?.image}/>
                                <br/><br/>
                                {parse(this.state.dataMicroPage?.page ?? "")}
                                <br/>
                                {(this.state.dataMicroPage?.tags ?? []).map((item: any, i: number) =>
                                    <Tag key={i} color="grey">{item}</Tag>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>

                <div style={{minHeight: 200}}/>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(MicroPage);
