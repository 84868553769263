import React, {Component} from 'react'
import {withRouter} from "../../hoc/withRouter"
import {CreditFormStates} from "./types/CreditFormTypes"
import {appDefaultArea} from "../../config/appDataConfig/appConfig"
import Cookies from "universal-cookie"
import CurrencyInput from "react-currency-input-field"
import Typography from "antd/lib/typography"
import PageHeader from "../../component/header/PageHeader"
import FooterSection from "../../component/pageSection/FooterSection"
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices"
import {Col, Image, Alert, Row, Spin, Input, Button, Checkbox, Modal, Card, Tooltip, Tag} from "antd"
import Form from "antd/lib/form"
import {Divider} from "antd"
import AreaProvinceSelect from "../../component/area/AreaProvinceSelect"
import AreaCitySelect from "../../component/area/AreaCitySelect"
import AreaDistrictSelect from "../../component/area/AreaDistrictSelect"
import AreaSubdistrictSelect from "../../component/area/AreaSubdistrictSelect"
import TextArea from "antd/es/input/TextArea"
import UploadIdCardImage from "../../component/image/UploadIdCardImage";
import SearchOutlined from "@ant-design/icons/SearchOutlined"
import LoadingOutlined from "@ant-design/icons/LoadingOutlined"
import ListPromoModal from "../../component/promo/ListPromoModal"
import {Link} from "react-router-dom"
import {b2bServices} from "../../services/b2b/B2bService"
import currencyFormat from "../../helper/currencyFormat"
import FincoSelect from "../../component/submission/FincoSelect"
import {leadService} from "../../services/trimitraIdeal/LeadServive"
import Collapse from "antd/lib/collapse";
import {getLocation} from "../../component/area/GetLocation";
import dayjs from "dayjs";
import DatePicker from "antd/lib/date-picker";

const {Panel} = Collapse;
const randomString: any = Array.from({length: 16}, () => Math.floor(Math.random() * 10)).join('');

class CreditForm extends Component<any, CreditFormStates> {
    private cookies = new Cookies()
    private readonly initState!: CreditFormStates
    private locationCheckInterval: any;

    constructor(props: any) {
        super(props)
        const {match} = this.props
        const variantCode = match.params.param
        const colorCode = match.params.param2

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea)
        }

        this.initState = {
            isDesktop: false,
            variantCode: variantCode,
            colorCode: colorCode,
            fetching: true,
            dataVariant: {},
            listVariantColor: [],
            dataVariantColor: {},
            submitButton: false,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
            purchaseMethod: "credit",
            agentCode: "",
            lat: this.cookies.get('_lat'),
            lng: this.cookies.get('_lng'),

            guarantorAndOwnerDifferent: false,

            guarantorIdCardImage: "",
            ownerIdCardImage: "",
            familyCardImage: "",
            imageMutation: "",
            imageVehicleRegistration: "",
            imageVehicleOwnership: "",

            familyCardNumber: "",
            guarantorIdCardNumber: "",
            guarantorFullName: "",
            guarantorPhoneNumber: "",
            guarantorProvinceCode: "",
            guarantorProvinceName: null,
            guarantorCityCode: "",
            guarantorCityName: null,
            guarantorDistrictCode: "",
            guarantorDistrictName: null,
            guarantorSubdistrictCode: "",
            guarantorSubdistrictName: null,
            guarantorPostalCode: "",
            guarantorHamlet: "",
            guarantorNeighbourhood: "",
            guarantorAddress: "",
            guarantorBirthPlace: "",
            guarantorBirthDate: dayjs("2000-01-01", 'YYYY-MM-DD'),

            ownerIdCardNumber: "",
            ownerFullName: "",
            ownerPhoneNumber: "",
            ownerEmail: "",
            ownerProvinceCode: "",
            ownerProvinceName: null,
            ownerCityCode: "",
            ownerCityName: null,
            ownerDistrictCode: "",
            ownerDistrictName: null,
            ownerSubdistrictCode: "",
            ownerSubdistrictName: null,
            ownerPostalCode: "",
            ownerHamlet: "",
            ownerNeighbourhood: "",
            ownerAddress: "",
            ownerBirthPlace: "",
            ownerBirthDate: dayjs("2000-01-01", 'YYYY-MM-DD'),

            idCardImageUrl: "",

            takeInDealer: false,
            freezeStatus: false,
            modalPromoShow: false,
            usedPromoData: {},
            amartaVipUid: "",
            alternativeColor: {
                code: "SAME_AS_ORDER",
                name: "warna sesuai pesanan"
            },
            tenorList: [11, 17, 23, 29, 35],
            tenor: 11,
            downPayment: 0,
            installmentAmount: 0,
            minimumDp: 0,
            maximumDp: 0,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
            fetchingCreditSimulation: false,
            fincoChosenCode: "GLOBAL",
            fincoChosenName: "PENAWARAN TERBAIK",
            amartaVipIsLead: false,
            fetchAgentCodeFromLeadNumber: false,
            disableAgentCode: false,
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 10)
        window.addEventListener("resize", this.handleResize)
        this.handleResize()
        await this.fetchVariant()
        await getLocation.initTrack()

        this.locationCheckInterval = setInterval(this.checkLocation, 1000);
    }

    checkLocation = () => {
        if (this.state.lat !== "") {
            this.onUpdateLocation();
        }
    }

    async fetchVariant() {
        try {
            const dataDetail = await trimitraCatalogServices.getCatalogVariant({areaCode: this.state.areaCode, variantCode: this.state.variantCode})
            const dataVariant = dataDetail?.data?.meta
            const dataColor = dataDetail?.data?.data
            const picked = dataColor.find((o: { color_code: string }) => o.color_code === this.state.colorCode)

            this.setState({
                fetching: false,
                dataVariant: dataVariant,
                listVariantColor: dataColor,
                dataVariantColor: picked,
                downPayment: Math.round(15 / 100 * (picked?.price ?? 0) / 1000000) * 1000000
            })
        } catch (e) {
            this.setState({
                fetching: false,
                dataVariant: {},
                listVariantColor: [],
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        if (this.locationCheckInterval) {
            clearInterval(this.locationCheckInterval);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve))

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({isDesktop: false})
        } else {
            this.setState({isDesktop: true})
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group)
        await this.promisedSetState({
            areaCode: data.city_group,
            submitButton: true,
        })
        window.location.reload()
    }

    guarantorPhoneNumberBlur = async () => {
        this.setState({
            fetchAgentCodeFromLeadNumber: true,
            disableAgentCode: false,
            submitButton: true,
            agentCode: "",
            amartaVipIsLead: false,
        })
        try {
            await leadService.setToken()
            const fetchResult = await leadService.getGlobalNotesByPhoneNumber({
                organizationCode: "amartachery",
                phoneNumber: (this.state.guarantorPhoneNumber.substring(0, 2) === "08") ? this.state.guarantorPhoneNumber.replace(/^.{2}/g, '628') : this.state.guarantorPhoneNumber,
            })

            if (fetchResult?.data?.success?.data?.length > 0) {
                this.setState({
                    amartaVipIsLead: true,
                    fetchAgentCodeFromLeadNumber: false,
                    disableAgentCode: true,
                    submitButton: false,
                    agentCode: fetchResult?.data?.success?.data[0]?.agentCode,
                })
            } else {
                this.setState({
                    fetchAgentCodeFromLeadNumber: false,
                    disableAgentCode: false,
                    submitButton: false,
                })
            }
        } catch (e) {
            this.setState({
                fetchAgentCodeFromLeadNumber: false,
                disableAgentCode: false,
                submitButton: false,
            })
        }
    }


    onFieldChange = <T extends keyof Pick<any, "installmentAmount" | "tenor" | "downPayment" | "takeInDealer" | "agentCode"
        | "guarantorBirthPlace" | "guarantorBirthDate" | "guarantorAndOwnerDifferent" | "guarantorFullName" | "guarantorIdCardNumber" | "guarantorPhoneNumber" | "familyCardNumber" | "guarantorPostalCode" | "guarantorHamlet" | "guarantorNeighbourhood" | "guarantorAddress"
        | "ownerBirthPlace" | "ownerBirthDate" | "ownerAndOwnerDifferent" | "ownerFullName" | "ownerIdCardNumber" | "ownerPhoneNumber" | "ownerEmail" | "ownerPostalCode" | "ownerHamlet" | "ownerNeighbourhood" | "ownerAddress"
    >>(target: T, value: any) => {
        const currentState: any = {...this.state}
        switch (target) {
            case "takeInDealer":
                currentState.takeInDealer = value
                break
            case "installmentAmount":
                currentState.installmentAmount = value
                break
            case "tenor":
                currentState.tenor = value
                break
            case "downPayment":
                currentState.downPayment = value
                break
            case "agentCode":
                currentState.agentCode = value
                break
            case "guarantorAndOwnerDifferent":
                currentState.guarantorAndOwnerDifferent = value
                break

            case "guarantorFullName":
                currentState.guarantorFullName = value
                break
            case "guarantorIdCardNumber":
                currentState.guarantorIdCardNumber = value.replace(/[^0-9.]/g, '')
                break
            case "guarantorPhoneNumber":
                currentState.guarantorPhoneNumber = value.replace(/[^0-9.]/g, '')
                break
            case "familyCardNumber":
                currentState.familyCardNumber = value.replace(/[^0-9.]/g, '')
                break
            case "guarantorPostalCode":
                currentState.guarantorPostalCode = value
                break
            case "guarantorHamlet":
                currentState.guarantorHamlet = value
                break
            case "guarantorNeighbourhood":
                currentState.guarantorNeighbourhood = value
                break
            case "guarantorAddress":
                currentState.guarantorAddress = value?.toUpperCase()
                break
            case "guarantorBirthPlace":
                currentState.guarantorBirthPlace = value
                break
            case "guarantorBirthDate":
                currentState.guarantorBirthDate = value
                break

            case "ownerFullName":
                currentState.ownerFullName = value
                break
            case "ownerIdCardNumber":
                currentState.ownerIdCardNumber = value.replace(/[^0-9.]/g, '')
                break
            case "ownerPhoneNumber":
                currentState.ownerPhoneNumber = value.replace(/[^0-9.]/g, '')
                break
            case "ownerEmail":
                currentState.ownerEmail = value
                break
            case "ownerPostalCode":
                currentState.ownerPostalCode = value
                break
            case "ownerHamlet":
                currentState.ownerHamlet = value
                break
            case "ownerNeighbourhood":
                currentState.ownerNeighbourhood = value
                break
            case "ownerAddress":
                currentState.ownerAddress = value?.toUpperCase()
                break
            case "ownerBirthPlace":
                currentState.ownerBirthPlace = value
                break
            case "ownerBirthDate":
                currentState.ownerBirthDate = value
                break
        }
        if (target === 'guarantorPhoneNumber') {
            if (currentState.guarantorPhoneNumber.length > 1) {
                if (currentState.guarantorPhoneNumber.substring(0, 2) !== '08') {
                    currentState.guarantorPhoneNumber = ""
                }
            }
        }
        if (target === 'ownerPhoneNumber') {
            if (currentState.ownerPhoneNumber.length > 1) {
                if (currentState.ownerPhoneNumber.substring(0, 2) !== '08') {
                    currentState.ownerPhoneNumber = ""
                }
            }
        }

        this.setState({
            ...currentState,
        })
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            guarantorProvinceCode: data.value,
            guarantorProvinceName: data.children,
            guarantorCityCode: "",
            guarantorCityName: null,
            guarantorDistrictCode: "",
            guarantorDistrictName: null,
            guarantorSubdistrictCode: "",
            guarantorSubdistrictName: null,
            guarantorPostalCode: ""
        })
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            guarantorCityCode: data.value,
            guarantorCityName: data.children,
            guarantorDistrictCode: "",
            guarantorDistrictName: null,
            guarantorSubdistrictCode: "",
            guarantorSubdistrictName: null,
            guarantorPostalCode: ""
        })
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            guarantorDistrictCode: data.value,
            guarantorDistrictName: data.children,
            guarantorSubdistrictCode: "",
            guarantorSubdistrictName: null,
            guarantorPostalCode: ""
        })
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            guarantorSubdistrictCode: data.value,
            guarantorSubdistrictName: data.children,
            guarantorPostalCode: data.postalCode
        })
    }


    onAreaProvinceChangeOwner = (data: any) => {
        this.setState({
            ownerProvinceCode: data.value,
            ownerProvinceName: data.children,
            ownerCityCode: "",
            ownerCityName: null,
            ownerDistrictCode: "",
            ownerDistrictName: null,
            ownerSubdistrictCode: "",
            ownerSubdistrictName: null,
            ownerPostalCode: ""
        })
    }

    onAreaCityChangeOwner = (data: any) => {
        this.setState({
            ownerCityCode: data.value,
            ownerCityName: data.children,
            ownerDistrictCode: "",
            ownerDistrictName: null,
            ownerSubdistrictCode: "",
            ownerSubdistrictName: null,
            ownerPostalCode: ""
        })
    }

    onAreaDistrictChangeOwner = (data: any) => {
        this.setState({
            ownerDistrictCode: data.value,
            ownerDistrictName: data.children,
            ownerSubdistrictCode: "",
            ownerSubdistrictName: null,
            ownerPostalCode: ""
        })
    }

    onAreaSubdistrictChangeOwner = (data: any) => {
        this.setState({
            ownerSubdistrictCode: data.value,
            ownerSubdistrictName: data.children,
            ownerPostalCode: data.postalCode
        })
    }


    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0')
    }
    validateEmail = (familyCardNumber: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(familyCardNumber)
    }

    onPromoChange = (data: any) => {
        this.setState({
            usedPromoData: data,
            modalPromoShow: false,
        })
    }

    createRandomString = async (length: number) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        let result = ''
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length)
            result += chars[randomIndex]
        }
        return result
    }

    onSubmit = async () => {
        let warnNotice = ""
        if (this.state.familyCardNumber.length !== 16) {
            warnNotice += "Nomor kk tidak valid, "
        }
        if (!this.state.familyCardImage) {
            warnNotice += "Image KK, "
        }
        if (this.state.guarantorIdCardNumber.length !== 16) {
            warnNotice += "Nomor KTP Pemohon tidak valid, "
        }
        if (!this.state.guarantorPhoneNumber) {
            warnNotice += "Nomor Telpon Pemohon, "
        }
        if (!this.state.guarantorFullName) {
            warnNotice += "Nama Lengkap Pemohon, "
        }
        if (!this.state.guarantorBirthDate) {
            warnNotice += "Tanggal Lahir Pemohon, "
        }
        if (!this.state.guarantorBirthPlace) {
            warnNotice += "Tempat Lahir Pemohon, "
        }
        if (!this.state.guarantorPhoneNumber) {
            warnNotice += "Nomor Telpon Pemohon, "
        }
        if (!this.state.guarantorIdCardImage) {
            warnNotice += "Image KTP Pemohon tidak valid, "
        }
        if (!this.state.guarantorProvinceCode) {
            warnNotice += "Provinsi Pemohon, "
        }
        if (!this.state.guarantorCityCode) {
            warnNotice += "Kota Pemohon, "
        }
        if (!this.state.guarantorDistrictCode) {
            warnNotice += "Kecamatan Pemohon, "
        }
        if (!this.state.guarantorSubdistrictCode) {
            warnNotice += "Kelurahan Pemohon, "
        }
        if (!this.state.guarantorPostalCode) {
            warnNotice += "Kode Pos Pemohon, "
        }
        if (!this.state.guarantorAddress) {
            warnNotice += "Alamat Identitas Pemohon, "
        }

        if (this.state.guarantorAndOwnerDifferent) {
            if (!this.state.ownerPhoneNumber) {
                warnNotice += "Nomor Telpon Pemilik, "
            }
            if (!this.state.ownerFullName) {
                warnNotice += "Nama Lengkap Pemilik, "
            }
            if (!this.state.ownerBirthDate) {
                warnNotice += "Tanggal Lahir Pemilik, "
            }
            if (!this.state.ownerBirthPlace) {
                warnNotice += "Tempat Lahir Pemilik, "
            }
            if (!this.state.ownerPhoneNumber) {
                warnNotice += "Nomor Telpon Pemilik, "
            }
            if (!this.state.ownerIdCardImage) {
                warnNotice += "Image KTP Pemilik tidak valid, "
            }
            if (!this.state.ownerProvinceCode) {
                warnNotice += "Provinsi Pemilik, "
            }
            if (!this.state.ownerCityCode) {
                warnNotice += "Kota Pemilik, "
            }
            if (!this.state.ownerDistrictCode) {
                warnNotice += "Kecamatan Pemilik, "
            }
            if (!this.state.ownerSubdistrictCode) {
                warnNotice += "Kelurahan Pemilik, "
            }
            if (!this.state.ownerPostalCode) {
                warnNotice += "Kode Pos Pemilik, "
            }
            if (!this.state.ownerAddress) {
                warnNotice += "Alamat Identitas Pemilik, "
            }
        }

        if (!this.state.tenor) {
            warnNotice += "tenor, "
        }
        if (!this.state.downPayment) {
            warnNotice += "down payment, "
        }
        if (!this.state.fincoChosenCode) {
            warnNotice += "leasing, "
        }
        if (!this.state.installmentAmount) {
            warnNotice += "cicilan, "
        }
        if (!this.state.lat) {
            warnNotice += "mohon izinkan allow location pada browser, "
        }
        if (warnNotice !== "") {
            const objError = warnNotice.split(",")
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>
                    })}
                </div>)
            })
            return false
        }

        const dataOffer = {
            amarta_vip_uid: "",
            amarta_vip_is_lead: this.state.amartaVipIsLead,
            agent_code: this.state.agentCode,
            company: "chery",
            source: "amartachery.com",
            purchase_method: this.state.purchaseMethod,
            area: this.state.areaCode,
            take_vehicle_in_dealer: this.state.takeInDealer,
            offer_broker: false,
            notes: "",
            promo_codes: (this.state.usedPromoData?.promoCode) ? [this.state.usedPromoData?.promoCode] : [],
            family_register_owner: {
                family_register_number: this.state?.familyCardNumber?.trim(),
                family_register_image: this.state?.familyCardImage,
            },
            contact: {
                phone_number_owner: this.state.guarantorPhoneNumber,
                phone_number_wa_available_owner: false,
            },
            id_card_owner_and_guarantor_different: this.props.guarantorAndOwnerDifferent,
            id_card_guarantor: {
                full_name: this.state.guarantorFullName?.trim(),
                id_card_number: this.state.guarantorIdCardNumber,
                id_card_image: this.state.guarantorIdCardImage,
                full_address: this.state.guarantorAddress,
                birth_date: dayjs(this.state.guarantorBirthDate).format("YYYY-MM-DD"),
                birth_place: this.state.guarantorBirthPlace,
            },
            address_guarantor: {
                full_address: this.state.guarantorAddress,
                province_code: this.state.guarantorProvinceCode,
                province_name: this.state.guarantorProvinceName,
                city_code: this.state.guarantorCityCode,
                city_name: this.state.guarantorCityName,
                district_name: this.state.guarantorDistrictName,
                district_code: this.state.guarantorDistrictCode,
                sub_district_code: this.state.guarantorSubdistrictCode,
                sub_district_name: this.state.guarantorSubdistrictName,
                zip_code: this.state.guarantorPostalCode,
                hamlet: this.addLeadingZeros(this.state.guarantorHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.guarantorNeighbourhood, 3)
            },
            id_card_owner: {
                full_name: (this.state.ownerFullName) ? this.state.ownerFullName?.trim() : this.state.guarantorFullName?.trim(),
                id_card_number: (this.state.ownerIdCardNumber) ? this.state.ownerIdCardNumber : this.state.guarantorIdCardNumber,
                id_card_image: (this.state.ownerIdCardImage) ? this.state.ownerIdCardImage : this.state.guarantorIdCardImage,
                full_address: (this.state.ownerAddress) ? this.state.ownerAddress : this.state.guarantorAddress,
                birth_date: (this.state.ownerIdCardNumber) ? dayjs(this.state.ownerBirthDate).format("YYYY-MM-DD") : dayjs(this.state.guarantorBirthDate).format("YYYY-MM-DD"),
                birth_place: (this.state.ownerIdCardNumber) ? this.state.ownerBirthPlace : this.state.guarantorBirthPlace,
            },
            address_owner: {
                full_address: (this.state.ownerAddress) ? this.state.ownerAddress : this.state.guarantorAddress,
                province_code: (this.state.ownerProvinceCode) ? this.state.ownerProvinceCode : this.state.guarantorProvinceCode,
                province_name: (this.state.ownerProvinceName) ? this.state.ownerProvinceName : this.state.guarantorProvinceName,
                city_code: (this.state.ownerCityCode) ? this.state.ownerCityCode : this.state.guarantorCityCode,
                city_name: (this.state.ownerCityName) ? this.state.ownerCityName : this.state.guarantorCityName,
                district_name: (this.state.ownerDistrictName) ? this.state.ownerDistrictName : this.state.guarantorDistrictName,
                district_code: (this.state.ownerDistrictCode) ? this.state.ownerDistrictCode : this.state.guarantorDistrictCode,
                sub_district_code: (this.state.ownerSubdistrictCode) ? this.state.ownerSubdistrictCode : this.state.guarantorSubdistrictCode,
                sub_district_name: (this.state.ownerSubdistrictName) ? this.state.ownerSubdistrictName : this.state.guarantorSubdistrictName,
                zip_code: (this.state.ownerPostalCode) ? this.state.ownerPostalCode : this.state.guarantorPostalCode,
                hamlet: (this.state.ownerHamlet) ? this.addLeadingZeros(this.state.ownerHamlet, 3) : this.addLeadingZeros(this.state.guarantorHamlet, 3),
                neighbourhood: (this.state.ownerNeighbourhood) ? this.addLeadingZeros(this.state.ownerNeighbourhood, 3) : this.addLeadingZeros(this.state.guarantorNeighbourhood, 3),
            },
            vehicle: {
                brand_name: this.state.dataVariantColor.brand_name,
                brand_uuid: this.state.dataVariantColor.brand_uuid,
                model_name: this.state.dataVariantColor.model_name,
                model_uuid: this.state.dataVariantColor.model_uuid,
                variant_name: this.state.dataVariantColor.variant_name,
                variant_uuid: this.state.dataVariantColor.variant_uuid,
                variant_code: this.state.dataVariantColor.variant_code,
                color: this.state.dataVariantColor.color_name,
                color_code: this.state.dataVariantColor.color_code,
                alternative_color: {
                    code: "SAME_AS_ORDER",
                    name: "warna sesuai pesanan"
                }
            },
            credit: {
                otr: this.state.dataVariantColor?.price,
                dp_amount: this.state.downPayment,
                tenor: this.state.tenor,
                installment_amount: this.state.installmentAmount,
                finco_code: this.state.fincoChosenCode,
                finco_branch: "",
                finco_name: this.state.fincoChosenName,
                finco_comission: 1,
            },
            documents: {
                mutation_documents: [
                    {
                        document_type: "mutasi",
                        document_image: this.state?.imageMutation,
                    }
                ],
                vehicle_registration_documents: [
                    {
                        document_type: "stnk",
                        document_image: this.state?.imageVehicleRegistration,
                    }
                ],
                vehicle_ownership_documents: [
                    {
                        document_type: "bpkb",
                        document_image: this.state?.imageVehicleOwnership,
                    }
                ],
            },
            location: {
                latitude: this.state.lat,
                longitude: this.state.lng
            }
        }

        console.log(dataOffer)

        this.setState({
            submitButton: true,
            freezeStatus: true,
        })

        try {
            await b2bServices.createSurvey(dataOffer).then(successData => {
                window.location.href = `/spk-credit/${successData.data.data.offer_code}`
            })
        } catch (error: any) {
            let errorMsg = "";
            if (error?.response?.data?.data) {
                for (const [, value] of Object.entries(error.response.data.data)) {
                    errorMsg += (value as any).msg + ", ";
                }
            }
            errorMsg = (errorMsg) ? errorMsg : "Error Server B2B"

            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + errorMsg
            });

            this.setState({submitButton: false, freezeStatus: false})
        }
    }

    changeDpPercentage = (data: number) => {
        let dpChosen = data / 100 * (this.state.dataVariantColor?.price ?? 0)
        dpChosen = Math.round(dpChosen / 1000000) * 1000000
        this.setState({downPayment: dpChosen})
    }

    blurDownPayment = () => {
        let dpChosen = Math.round(this.state.downPayment / 1000000) * 1000000
        this.setState({downPayment: dpChosen})
    }

    blurInstallmentAmount = () => {
        let dpChosen = Math.round(this.state.installmentAmount / 100000) * 100000
        this.setState({installmentAmount: dpChosen})
    }

    blurTenor = () => {
        if (Number(this.state.tenor) > 100) {
            this.setState({tenor: 100})
        }
    }

    onFincoSelectChange = async (data: any) => {
        this.setState({fincoChosenCode: data.value, fincoChosenName: data.children})
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "guarantor") {
            this.setState({
                guarantorIdCardImage: data.image_url,
            });
        }
        if (data.id === "owner") {
            this.setState({
                ownerIdCardImage: data.image_url,
            });
        }
        if (data.id === "family_card") {
            this.setState({
                familyCardImage: data.image_url,
            });
        }
        if (data.id === "mutation") {
            this.setState({
                imageMutation: data.image_url,
            });
        }
        if (data.id === "vehicle_registration") {
            this.setState({
                imageVehicleRegistration: data.image_url,
            });
        }
        if (data.id === "vehicle_ownership") {
            this.setState({
                imageVehicleOwnership: data.image_url,
            });
        }
    }

    onUpdateLocation = async () => {
        this.setState({
            lat: this.cookies.get('_lat'),
            lng: this.cookies.get('_lng'),
        })
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <div style={this.state.fetching ? {textAlign: "center", padding: 70, minHeight: "70vh"} : {display: 'none'}}>
                    <Spin style={{marginTop: 100}} size={'large'} tip="Loading..."/>
                </div>
                <div style={this.state.fetching ? {display: 'none'} : {}}>
                    <Row style={{padding: 20, paddingTop: 100}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <div style={this.state.dataVariantColor?.price ? {display: 'none'} : {}}>
                                <div style={{marginTop: 200}}/>
                                <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7}}>Variant Belum Tersedia di Wilayah ini</div>
                                <div style={{textAlign: "center", fontSize: "100%", marginBottom: 20, color: "gray"}}>Kami akan segera mengahdirkanya di wilayah anda.</div>
                                <div style={{marginTop: 200}}/>
                            </div>
                            <div style={!this.state.dataVariantColor?.price ? {display: 'none'} : {}}>
                                <div>
                                    <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7}}>Pengajuan Kredit</div>
                                    <div style={{textAlign: "center", fontSize: "100%", marginBottom: 20, color: "gray"}}>Silakan isi data diri Anda pada form di bawah ini</div>
                                </div>
                                <Row>
                                    <Col style={{paddingTop: 50}} xxl={{span: 7}} xl={{span: 7}} lg={{span: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <Row>
                                            <Col span={16} style={{paddingRight: 20}}>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.model_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.variant_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.color_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>OTR: {currencyFormat(this.state.dataVariantColor?.price)}</Col>
                                                </Row>
                                                <Link to={`/variant/${this.state.variantCode}`}>
                                                    <Button style={{marginTop: 10, width: 150}} size={"small"} danger>Ubah Warna</Button>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <Image style={{width: "50%"}} src={this.state.dataVariantColor?.url_image}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col style={{paddingTop: 20}} xxl={{span: 17}} xl={{span: 17}} lg={{span: 17}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <Alert
                                            message="Allow Access Location"
                                            description={(this.state.lat && this.state.lng) ? 'Location Ok, silakan lanjut pengisian data' : 'Sebelum melakukan pengisian mohon izinkan Allow Location Permission pada browser.'}
                                            type="info"
                                            showIcon
                                            style={{marginBottom: 20}}
                                        />
                                        <Row>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nama Lengkap Pemohon *</i>
                                                    <Input value={this.state.guarantorFullName} onChange={event => this.onFieldChange("guarantorFullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemohon"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nomor KTP Pemohon *</i>
                                                    <Input
                                                        maxLength={16}
                                                        value={this.state.guarantorIdCardNumber}
                                                        placeholder="Nomor KTP Pemohon"
                                                        onChange={event => this.onFieldChange("guarantorIdCardNumber", event.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nomor Telpon Pemohon *</i>
                                                    <Input
                                                        value={this.state.guarantorPhoneNumber}
                                                        maxLength={17}
                                                        placeholder="Format: 08xxxxxxxx"
                                                        onBlur={this.guarantorPhoneNumberBlur}
                                                        onChange={event => this.onFieldChange("guarantorPhoneNumber", event.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nomor Kartu Keluarga *</i>
                                                    <Input value={this.state.familyCardNumber} onChange={event => this.onFieldChange("familyCardNumber", event.target.value)} placeholder="Kartu Keluarga"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemohon *</i>
                                                    <Input value={this.state.guarantorBirthPlace} placeholder="Tempat Lahir Sesuai KTP" onChange={event => this.onFieldChange("guarantorBirthPlace", event.target.value)}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item className={'padTop-7'}>
                                                    <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemohon *</i>
                                                    <DatePicker value={this.state.guarantorBirthDate} onChange={event => this.onFieldChange("guarantorBirthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Provinsi Pemohon *</i>
                                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceName={this.state.guarantorProvinceName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kota Pemohon *</i>
                                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.guarantorProvinceCode} cityName={this.state.guarantorCityName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kecamatan Pemohon *</i>
                                                    <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.guarantorCityCode} districtName={this.state.guarantorDistrictName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kelurahan Pemohon *</i>
                                                    <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.guarantorDistrictCode} subdistrictName={this.state.guarantorSubdistrictName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kode Pos Pemohon *</i>
                                                    <Input value={this.state.guarantorPostalCode} onChange={event => this.onFieldChange("guarantorPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Row>
                                                    <Col span={12} style={{paddingRight: '2px'}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>RT Pemohon</i>
                                                            <Input maxLength={3} value={this.state.guarantorHamlet} onChange={event => this.onFieldChange("guarantorHamlet", event.target.value)} placeholder="RT Pemohon"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} style={{paddingLeft: '2px'}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>RW Pemohon</i>
                                                            <Input maxLength={3} value={this.state.guarantorNeighbourhood} onChange={event => this.onFieldChange("guarantorNeighbourhood", event.target.value)} placeholder="RW Pemohon"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Alamat Pemohon *</i>
                                                    <TextArea showCount maxLength={200} value={this.state.guarantorAddress} onChange={event => this.onFieldChange("guarantorAddress", event.target.value)} placeholder="Alamat"/>
                                                </Form.Item>
                                            </Col>

                                            <Form.Item>
                                                <Checkbox onChange={e => this.onFieldChange("guarantorAndOwnerDifferent", e.target.checked)} checked={this.state.guarantorAndOwnerDifferent}>
                                                    <i className={`text-small-grey-nopadding`}>KTP pemilik <b>berbeda</b> dari KTP Pemohon.</i>
                                                </Checkbox>
                                            </Form.Item>

                                            <div style={this.state.guarantorAndOwnerDifferent ? {} : {display: 'none'}}>
                                                <Divider orientation="left" plain>Data Pemilik</Divider>
                                                <Row>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Nama Lengkap Pemilik *</i>
                                                            <Input value={this.state.ownerFullName} onChange={event => this.onFieldChange("ownerFullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemilik"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Nomor KTP Pemilik *</i>
                                                            <Input
                                                                maxLength={16}
                                                                value={this.state.ownerIdCardNumber}
                                                                placeholder="Nomor KTP Pemilik"
                                                                onChange={event => this.onFieldChange("ownerIdCardNumber", event.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Nomor Telpon Pemilik *</i>
                                                            <Input
                                                                value={this.state.ownerPhoneNumber}
                                                                maxLength={17}
                                                                placeholder="Format: 08xxxxxxxx"
                                                                onChange={event => this.onFieldChange("ownerPhoneNumber", event.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        {/*<Form.Item>*/}
                                                        {/*    <i className={`text-small-grey-nopadding`}>Email Pemilik *</i>*/}
                                                        {/*    <Input value={this.state.ownerEmail} onChange={event => this.onFieldChange("ownerEmail", event.target.value)} placeholder="ownerEmail"/>*/}
                                                        {/*</Form.Item>*/}
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemilik *</i>
                                                            <Input value={this.state.ownerBirthPlace} placeholder="Tempat Lahir Sesuai KTP" onChange={event => this.onFieldChange("ownerBirthPlace", event.target.value)}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item className={'padTop-7'}>
                                                            <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemilik *</i>
                                                            <DatePicker value={this.state.ownerBirthDate} onChange={event => this.onFieldChange("ownerBirthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Provinsi Pemilik *</i>
                                                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChangeOwner} country={"idn"} provinceName={this.state.ownerProvinceName}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kota Pemilik *</i>
                                                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChangeOwner} provinceCode={this.state.ownerProvinceCode} cityName={this.state.ownerCityName}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kecamatan Pemilik *</i>
                                                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChangeOwner} cityCode={this.state.ownerCityCode} districtName={this.state.ownerDistrictName}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kelurahan Pemilik *</i>
                                                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChangeOwner} districtCode={this.state.ownerDistrictCode} subdistrictName={this.state.ownerSubdistrictName}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Kode Pos Pemilik *</i>
                                                            <Input value={this.state.ownerPostalCode} onChange={event => this.onFieldChange("ownerPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Row>
                                                            <Col span={12} style={{paddingRight: '2px'}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>RT Pemilik</i>
                                                                    <Input maxLength={3} value={this.state.ownerHamlet} onChange={event => this.onFieldChange("ownerHamlet", event.target.value)} placeholder="RT Pemilik"/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12} style={{paddingLeft: '2px'}}>
                                                                <Form.Item>
                                                                    <i className={`text-small-grey-nopadding`}>RW Pemilik</i>
                                                                    <Input maxLength={3} value={this.state.ownerNeighbourhood} onChange={event => this.onFieldChange("ownerNeighbourhood", event.target.value)} placeholder="RW Pemilik"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>Alamat Pemilik *</i>
                                                            <TextArea showCount maxLength={200} value={this.state.ownerAddress} onChange={event => this.onFieldChange("ownerAddress", event.target.value)} placeholder="Alamat"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <Divider orientation="left" plain>Dokumen</Divider>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'guarantor'} title={`KTP Pemohon`} number={this.state.guarantorIdCardNumber} name={this.state.guarantorFullName}/>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'family_card'} title={`Kartu Keluarga`} number={this.state.familyCardNumber}/>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={this.state.guarantorAndOwnerDifferent ? {paddingLeft: 5, paddingRight: 5} : {display: 'none'}}>
                                                <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'owner'} title={`KTP Pemilik`} number={this.state.ownerIdCardNumber} name={this.state.ownerIdCardNumber}/>
                                            </Col>

                                            <Divider/>

                                            <Collapse defaultActiveKey={['1']} ghost>
                                                <Panel header={`Upload Opsional Dokumen`} key="2">
                                                    <Row>
                                                        <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                            <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'mutation'} title={`Image Mutasi`} number={randomString} name={``} hideInfo={true}/>
                                                        </Col>
                                                        <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                            <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'vehicle_registration'} title={`Image STNK`} number={randomString} name={``} hideInfo={true}/>
                                                        </Col>
                                                        <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                            <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'vehicle_ownership'} title={`Image BPKB Kendaraan Saat Ini`} number={randomString} name={``} hideInfo={true}/>
                                                        </Col>
                                                    </Row>
                                                </Panel>
                                            </Collapse>

                                            <Divider/>

                                            <Card title="Skema Kredit" size={"small"} style={{width: "100%"}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Leasing</i><br/>
                                                    <FincoSelect onFincoSelectChangeProps={this.onFincoSelectChange} value={this.state.fincoChosenCode}/>
                                                </Form.Item>
                                                <div style={{marginBottom: 10}}>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 15% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(15)} color="#f50">15%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 20% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(20)} color="#e2c430">20%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 25% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(25)} color="#87d068">25%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 30% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(30)} color="#87d068">30%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 40% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(40)} color="#87d068">40%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 50% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(50)} color="#87d068">50%</Tag>
                                                    </Tooltip>
                                                </div>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Uang Muka</i> <i style={{color: "gray", fontSize: '70%'}}>(Kelipatan 1jt)</i>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        groupSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'Rp. '}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.downPayment}
                                                        onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurDownPayment}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Tenor</i><br/>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        max={100}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.tenor}
                                                        onValueChange={value => this.onFieldChange("tenor", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurTenor}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Cicilan</i> <i style={{color: "gray", fontSize: '70%'}}>(Kelipatan 100k)</i>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        groupSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'Rp. '}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.installmentAmount}
                                                        onValueChange={value => this.onFieldChange("installmentAmount", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurInstallmentAmount}
                                                    />
                                                </Form.Item>
                                                <Row>
                                                    <Col span={24}>
                                                        <br/><i style={{fontSize: '80%'}}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br/>
                                                    </Col>
                                                </Row>
                                            </Card><br/>

                                            <Divider/>

                                            <Col span={24} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <Form.Item>
                                                    <Checkbox onChange={e => this.onFieldChange("takeInDealer", e.target.checked)} checked={this.state.takeInDealer}>
                                                        Saya ingin mengambil unit di dealer.
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Promo</i>
                                                    <Input value={this.state.usedPromoData?.promoCode} onClick={() => this.setState({modalPromoShow: true})} style={{cursor: "pointer"}} readOnly={true} placeholder="Lebih Hemat Dengan Promo" prefix={<SearchOutlined className={'grey-color'}/>}/>
                                                    <div style={this.state.usedPromoData?.note ? {} : {display: 'none'}}>
                                                        <br/>
                                                        <i><Typography.Text mark>{this.state.usedPromoData?.note}</Typography.Text></i>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kode Agen</i>
                                                    <Input
                                                        suffix={this.state.fetchAgentCodeFromLeadNumber && (<Spin indicator={<LoadingOutlined/>}/>)}
                                                        disabled={this.state.disableAgentCode}
                                                        value={this.state.agentCode}
                                                        onChange={event => this.onFieldChange("agentCode", event.target.value)}
                                                        placeholder="Dapat Dikosongkan"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Divider/>
                                            <Button onClick={() => this.onSubmit()} loading={this.state.submitButton} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Submit Data SPK</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div style={{minHeight: 100}}/>
                <FooterSection/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Promo"
                    maskClosable={false}
                    open={this.state.modalPromoShow}
                    onOk={() => this.setState({modalPromoShow: false, usedPromoData: {}})}
                    onCancel={() => this.setState({modalPromoShow: false, usedPromoData: {}})}
                    footer={null}
                >
                    <ListPromoModal
                        onPromoChangeProps={this.onPromoChange}
                        company={"amarta"}
                        purchaseMethod={this.state.purchaseMethod}
                        area={this.state.areaCode}
                        vehicle={this.state.dataVariantColor}
                        alternativeColor={this.state.alternativeColor?.code}
                        amartaVipUid={this.state.amartaVipUid}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

export default withRouter(CreditForm)