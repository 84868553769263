import axios from "axios";
import {baseUrlPromo, xApiKeyPromo} from "../../config/apiConfig/apiConfig";
import currencyFormat from "../../helper/currencyFormat";

const companyCode = 'chery';

class PromoServices {
    private axios = axios.create({
        baseURL: baseUrlPromo,
    });

    public async getPromo(params?: { cityGroup?: string, purchaseMethod?: string, brand?: string, model?: string, variant?: string, tenor?: any, allowAgent?: any, log?: any }) {
        const queries: any = {
            promo_type: 'new_vehicle',
            ...(params?.cityGroup && {
                city_group: params.cityGroup,
            }),
            ...(params?.purchaseMethod && {
                purchase_method: params.purchaseMethod,
            }),
            ...(params?.brand && {
                vehicle_brand: params.brand,
            }),
            ...(params?.model && {
                vehicle_model: params.model,
            }),
            ...(params?.variant && {
                vehicle_variant: params.variant,
            }),
            ...(params?.allowAgent && {
                allow_agent: params.allowAgent,
            }),
            ...(params?.log && {
                log: params.log,
            })
        }

        if (params?.tenor) {
            queries.tenor = params?.tenor
        }

        try {
            return await this.axios.get<any>(`/promo/${companyCode}`, {
                headers: {"X-Api-Key": xApiKeyPromo,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getDetailPromo(params?: { alternativeColor?: any, transactionAmount?: number, promoCode?: string, cityGroup?: string, purchaseMethod?: string, brand?: string, model?: string, variant?: string, tenor?: any, transactionValue?: any, fincoCode?: any, amartaVipUid?: string }) {
        const queries: any = {
            promo_type: 'new_vehicle',
            ...(params?.cityGroup && {
                city_group: params.cityGroup,
            }),
            ...(params?.purchaseMethod && {
                purchase_method: params.purchaseMethod,
            }),
            ...(params?.brand && {
                vehicle_brand: params.brand,
            }),
            ...(params?.model && {
                vehicle_model: params.model,
            }),
            ...(params?.variant && {
                vehicle_variant: params.variant,
            }),
            ...(params?.transactionValue && {
                transaction_amount: params.transactionValue,
            }),
            ...(params?.amartaVipUid && {
                amarta_vip_uid: params.amartaVipUid,
            })
        }

        if (params?.tenor) {
            queries.tenor = params?.tenor
        }

        if (params?.fincoCode) {
            if (params?.fincoCode !== "GLOBAL") {
                queries.finco_code = params?.fincoCode
            }
        }

        if (params?.alternativeColor) {
            queries.alternative_color = params?.alternativeColor
        }

        try {
            const dataFetchDetailPromo = await this.axios.get<any>(`/promo/${companyCode}/${params?.promoCode}`, {
                headers: {"X-Api-Key": xApiKeyPromo,},
                params: {...queries,}
            });

            let finalDiscount = 0;
            if (dataFetchDetailPromo.data.data.discount_type === 'nominal') {
                finalDiscount = parseInt(dataFetchDetailPromo.data.data.discount_value);
            } else {
                finalDiscount = (params?.transactionAmount ?? 0) - ((parseInt(dataFetchDetailPromo.data.data.discount_value) / 100) * (params?.transactionAmount ?? 0));
            }

            if (parseInt(dataFetchDetailPromo.data.data.maximum_promo_value) > 0) {
                finalDiscount = (finalDiscount > parseInt(dataFetchDetailPromo.data.data.maximum_promo_value)) ? parseInt(dataFetchDetailPromo.data.data.maximum_promo_value) : finalDiscount;
            }

            return {
                promoCode: params?.promoCode,
                showAgent: dataFetchDetailPromo.data.data.allow_agent,
                price: params?.transactionAmount,
                discountType: dataFetchDetailPromo.data.data.discount_type,
                discountValue: parseInt(dataFetchDetailPromo.data.data.discount_value),
                discountMaximum: parseInt(dataFetchDetailPromo.data.data.maximum_promo_value),
                finalDiscount: finalDiscount,
                note: "*Anda berkesempatan mendapatkan potongan sebesar " + currencyFormat(finalDiscount)
            };
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getInfoDetailPromo(params?: { promoCode?: string }) {
        const queries: any = {
            promo_type: "new_vehicle",
            // only_show_information: "true",
        }
        try {
            return await this.axios.get<any>(`/promo/${companyCode}/${params?.promoCode}`, {
                headers: {"X-Api-Key": xApiKeyPromo,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const promoServices = new PromoServices();
