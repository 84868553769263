import React, {useState} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Crop.css";
import {CloudUploadOutlined, RadiusSettingOutlined} from "@ant-design/icons";
import {Button, Col, Modal, Row, Spin} from "antd";

export const CropIdCardImage: any = (props: any) => {
    const [showCropArea, setshowCropArea] = useState(true);
    const [cropStatus, setCropStatus] = useState(false);
    const [freezeStatus, setFreezeStatus] = useState(false);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        if (files[0]) {
            reader.readAsDataURL(files[0]);
        }
    };
    const [image, setImage] = useState("/assets/img/sample.png");

    const getCropData = async () => {
        await setFreezeStatus(true);
        const delay = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
        if (typeof cropper !== "undefined") {
            await setshowCropArea(false);
            await setCropData(cropper.getCroppedCanvas().toDataURL());
            await setCropStatus(true);

            const x = cropper.getCroppedCanvas().toDataURL();
            await delay(300);
            const newImage = await resizeImage(x, 700, 700);
            await delay(300);
            await props.onCropImage(newImage);
            await delay(300);
        }
        await setFreezeStatus(false);
    };

    const showCrop = () => {
        setshowCropArea(true);
    };

    const resizeImage = async (base64Str: any, imgWidth: number, imgHeight: number) => {
        let img = new Image();
        img.src = base64Str;
        let canvas = document.createElement('canvas');
        let MAX_WIDTH = imgWidth;
        let MAX_HEIGHT = imgHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx: any = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL();
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={24} xl={24} className={`pad-10 mb-10`}>
                    <label className="custom-file-upload" onClick={showCrop}>
                        <input style={{display: "none"}} disabled={props.params?.processing} type="file" multiple onChange={onChange}/>
                        <i className="fa fa-cloud-upload"/> <CloudUploadOutlined/> Upload Gambar
                    </label>

                    <span style={showCropArea ? {} : {display: 'none'}}>
                        <Button onClick={getCropData} loading={freezeStatus} style={{background: "#f33232", border: "#f33232", color: "#fff", float: "right"}} type="primary" icon={<RadiusSettingOutlined/>}> Crop Image </Button>
                    </span>
                    <div style={showCropArea ? {marginTop: 10} : {display: 'none'}}>
                        <Cropper
                            style={{width: "100%"}}
                            zoomTo={0.1}
                            initialAspectRatio={16 / 9}
                            aspectRatio={16 / 9}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={500}
                            minCropBoxWidth={500}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    </div>
                </Col>

                <Col style={cropStatus ? {} : {display: 'none'}} xs={24} xl={10} className={`pad-10`}>
                    <br/>
                    <div style={cropData !== "#" ? {width: "100%"} : {display: 'none'}}>
                        <img style={{width: "100%"}} src={cropData} alt="please attach file then crop"/>
                    </div>
                </Col>

                <Col style={cropStatus ? {} : {display: 'none'}} xs={24} xl={2} className={`pad-10`}></Col>
            </Row>

            <Modal
                open={freezeStatus}
                closeIcon={true}
                footer={null}
            >
                <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                </div>

                <div style={{textAlign: "center", padding: 20}}>
                    Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default CropIdCardImage;
