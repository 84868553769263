import axios from "axios";

class OfferPdfService {
    private axios = axios.create({
        baseURL: 'https://asia-southeast1-autotrimitra.cloudfunctions.net/api-offer-otodis--create-pdf',
    });

    private axiosFile = axios.create({
        baseURL: 'https://asia-southeast1-autotrimitra.cloudfunctions.net/api-offer-otodis--create-pdf',
        responseType: 'blob'
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        this.axiosFile.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async create(inputData?: any) {
        try {
            return await this.axiosFile.get<any>('/spk/generate-pdf?company=chery&spk_code=' + inputData);
        } catch (e: any) {
            console.log(e);
            throw new Error(e.response.data.message);
        }
    }
}

export const offerPdfService = new OfferPdfService();
