import React, {Component} from "react";
import {Col, Divider, Row} from "antd";

class VariantDetail extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            indexPosition: 0,
            colorName: "",
            colorCode: "",
        }
        this.state = {...this.initState}
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>


                {this.props.isDesktop ?
                    <Row className={`mt-50`} style={{backgroundColor: "#fff"}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Row style={this.props.data?.detail_image?.[0]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[0]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[0]?.description}
                                    </div>
                                </Col>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[0]?.url}/>
                                </Col>
                            </Row>
                            <Row style={this.props.data?.detail_image?.[1]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[1]?.url}/>
                                </Col>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[1]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[1]?.description}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={this.props.data?.detail_image?.[2]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[2]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[2]?.description}
                                    </div>
                                </Col>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[2]?.url}/>
                                </Col>
                            </Row>
                            <Row style={this.props.data?.detail_image?.[3]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[3]?.url}/>
                                </Col>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[3]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[3]?.description}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={this.props.data?.detail_image?.[4]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[4]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[4]?.description}
                                    </div>
                                </Col>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[4]?.url}/>
                                </Col>
                            </Row>
                            <Row style={this.props.data?.detail_image?.[5]?.caption ? {padding: 20} : {display: 'none'}}>
                                <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={this.props.data?.detail_image?.[5]?.url}/>
                                </Col>
                                <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                        {this.props.data?.detail_image?.[5]?.caption}
                                    </div>
                                    <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                        {this.props.data?.detail_image?.[5]?.description}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <Row className={`mt-50`} style={{backgroundColor: "#fff"}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>

                            {(this.props.data?.detail_image ?? []).map((item: any, i: number) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Row style={item.caption ? {padding: 20} : {display: 'none'}}>
                                            <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                                    {item.caption}
                                                </div>
                                                <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                                    {item.description}
                                                </div>
                                            </Col>
                                            <Col xxl={{span: 8, offset: 2}} xl={{span: 8, offset: 2}} lg={{span: 8, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <img alt={`imageimage`} style={{padding: 20, width: "90%"}} src={item.url}/>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                );
                            })}
                        </Col>
                    </Row>
                }

                <Row>
                    <Divider/>
                </Row>

                <Row className={`mt-50`} style={{backgroundColor: "#fff"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row style={this.props.data?.detail_video?.[0]?.caption ? {padding: 20} : {display: 'none'}}>
                            <Col xxl={{span: 10}} xl={{span: 10}} lg={{span: 10}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <div style={this.props.isDesktop ? {paddingTop: 30, paddingLeft: 50, paddingBottom: 10} : {padding: 10}} className={`detail-product-price`}>
                                    {this.props.data?.detail_video?.[0]?.caption}
                                </div>
                                <div style={this.props.isDesktop ? {paddingLeft: 50, paddingRight: 50} : {padding: 10}} className={`detail-110`}>
                                    {this.props.data?.detail_video?.[0]?.description}
                                </div>
                            </Col>
                            <Col xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <iframe width="100%" height="350" src={this.props.data?.detail_video?.[0]?.url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}

export default VariantDetail;
