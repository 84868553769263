import React, {Component} from 'react';
import {PromoStates} from "./types/PromoTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {Breadcrumb, Badge, Button, Checkbox, Col, Empty, Row, Select, Spin} from "antd";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {promoServices} from "../../services/promo/PromoService";
import CardPromo from "../../component/card/CardPromo";
import Search from "antd/es/input/Search";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";

class Promo extends Component<any, PromoStates> {
    private cookies = new Cookies();
    private readonly initState!: PromoStates;

    constructor(props: any) {
        super(props);

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            fetching: true,
            methodSelected: "all",
            modelSelected: "all",
            allowAgent: false,
            listModel: [],
            dataPromo: [],
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onFieldChange = <T extends keyof Pick<any, "methodSelected" | "modelSelected" | "allowAgent">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "methodSelected":
                currentState.methodSelected = value;
                break;
            case "modelSelected":
                currentState.modelSelected = value;
                break;
            case "allowAgent":
                currentState.allowAgent = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    fetchAllModelProduct = async () => {
        try {
            const result = await trimitraCatalogServices.getCatalogModel({areaCode: this.state.areaCode});
            this.setState({
                listModel: result.data.data ?? []
            });
        } catch (e) {
            this.setState({
                listModel: []
            });
        }
    }

    fetchDetailPromo = async (data: string) => {
        if (data) {
            this.setState({fetching: true});
            try {
                const x = [];
                const fetchDetail = await promoServices.getInfoDetailPromo({promoCode: data?.trim()});
                x.push(fetchDetail?.data?.data);
                this.setState({
                    dataPromo: x,
                    fetching: false,
                });
            } catch (e) {
                this.setState({
                    dataPromo: [],
                    fetching: false,
                });
            }
        }
    }

    fetchPromoData = async (fetchType: string) => {
        await this.promisedSetState({
            fetching: true,
        });
        try {
            const result = await promoServices.getPromo({
                cityGroup: this.state.areaCode,
                purchaseMethod: (this.state.methodSelected !== 'all') ? this.state.methodSelected : "",
                model: (this.state.modelSelected !== 'all') ? this.state.modelSelected : "",
                allowAgent: this.state.allowAgent,
                log: false,
            });
            this.setState({
                dataPromo: result?.data?.data,
                fetching: false,
            });
        } catch (e) {
            this.setState({
                dataPromo: [],
                fetching: false,
            });
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        this.fetchAllModelProduct();

        this.fetchPromoData("fetch");
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        await this.fetchPromoData("fetch");
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta Chery</Breadcrumb.Item>
                            <Breadcrumb.Item>Promo</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>


                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Promo AmartaChery</div>
                                <br/>
                                <div className={`detail-product-caption`}>Dapatkan penawaran terbaik Chery {this.state.areaCode}, gunakan fitur pencarian untuk mendapatkan promo yang diinginkan.</div>
                                <br/>
                                <Select
                                    bordered={false}
                                    value={this.state.methodSelected}
                                    onChange={event => this.onFieldChange("methodSelected", event)}
                                    style={{fontSize: "80%", width: 140, paddingTop: 2, paddingBottom: 2}}
                                >
                                    <Select.Option value="all">Semua Metode</Select.Option>
                                    <Select.Option value="cash">Tunai</Select.Option>
                                    <Select.Option value="credit">Kredit</Select.Option>
                                </Select>
                                <Select
                                    bordered={false}
                                    onChange={event => this.onFieldChange("modelSelected", event)}
                                    value={this.state.modelSelected}
                                    style={{fontSize: "80%", width: 140, paddingTop: 2, paddingBottom: 2}}
                                >
                                    <Select.Option value="all">Semua Model</Select.Option>
                                    {this.state.listModel.map((item: any, i: number) =>
                                        <Select.Option key={i} value={item.model_name}>{item.model_name}</Select.Option>
                                    )}
                                </Select>
                                <Checkbox checked={this.state.allowAgent} onChange={e => this.onFieldChange("allowAgent", e.target.checked)}>
                                    <span style={{fontSize: "80%"}}>Agen</span>
                                </Checkbox>
                                <br/><br/>
                                <Button onClick={() => this.fetchPromoData("log")} danger type="primary">Temukan Promo</Button>
                            </Col>
                            <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/Wallet_Monochromatic.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div style={this.state.fetching ? {textAlign: "center", paddingTop: "250px", paddingBottom: "200px"} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div style={!this.state.fetching ? {} : {display: 'none'}}>
                    <Row className={`p-20 mt-10`}>
                        <Col xxl={{span: 14, offset: 5}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Search className={`p-10`} placeholder="masukan kode promo" onSearch={this.fetchDetailPromo} style={{width: 300}}/>

                            <Row>
                                {this.state.dataPromo.map((item: any, i: number) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                <Badge.Ribbon color={(item?.purchase_method[0] === "credit" ? "#64b48f" : "blue")} text={(item?.purchase_method[0] === "credit" ? "Kredit" : "Tunai")}>
                                                    <CardPromo data={item}/>
                                                </Badge.Ribbon>
                                            </Col>
                                        </React.Fragment>
                                    );
                                })}
                                <div style={this.state.dataPromo?.length < 1 ? {textAlign: "center", paddingLeft: `${(this.state.isDesktop) ? "40%" : "20%"}`} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div style={{minHeight: 200}}/>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(Promo);
