import React from "react";
import "./Testimonial.css";
import {Col, Row} from "antd";

const TestimonialDetail = (props: any) => {
    return (
        <React.Fragment>
            <div className={`card-testimony`}>
                <Row>
                    <Col xl={{span: 3}} lg={{span: 3}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                        <img className={`testi-img`} alt={`imageamarta`} width={'40'} src={'/assets/img/testimoni.png'}/>
                    </Col>
                    <Col xl={{span: 20}} lg={{span: 20}} md={{span: 18}} sm={{span: 18}} xs={{span: 18}}>
                        <div className={`testi-name`}>{props.name}</div>
                    </Col>
                    <Col span={24}>
                        <p className={`testi-p`}>{props.testimony}</p>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default TestimonialDetail;
