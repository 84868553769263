import React, {Component} from "react";
import Cookies from 'universal-cookie';
import {Button, Card, Checkbox, Select, Col, Divider, Empty, Form, Input, Modal, Row, Spin} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import dayjs from "dayjs";
import DatePicker from "antd/lib/date-picker";
import TextArea from "antd/es/input/TextArea";

class BiodataEmploymentBackground extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            dataEmploymentBackground: [],
            tempJobEmployer: null,
            tempJobEmployerLocation: "",
            tempJobPosition: "",
            tempJobDescription: "",
            tempStartDate: dayjs("2000-01", 'YYYY-MM'),
            tempEndDate: dayjs("2003-01", 'YYYY-MM'),
            tempNotes: "",
            tempCurrentlyWorkHere: false,
            occupation: null,
            occupationName: "",
        }
    }

    onFieldChange = async <T extends keyof Pick<any, "tempJobPosition" | "tempJobEmployer" | "tempJobDescription" | "tempJobEmployerLocation" | "tempStartDate" | "tempEndDate" | "tempNotes" | "tempCurrentlyWorkHere">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "tempJobEmployer":
                currentState.tempJobEmployer = value;
                break;
            case "tempJobPosition":
                currentState.tempJobPosition = value;
                break;
            case "tempJobDescription":
                currentState.tempJobDescription = value;
                break;
            case "tempJobEmployerLocation":
                currentState.tempJobEmployerLocation = value;
                break;
            case "tempStartDate":
                if (value) {
                    currentState.tempStartDate = value;
                }
                break;
            case "tempEndDate":
                if (value) {
                    currentState.tempEndDate = value;
                }
                break;
            case "tempNotes":
                currentState.tempNotes = value;
                break;
            case "tempCurrentlyWorkHere":
                currentState.tempCurrentlyWorkHere = value;
                break;
        }

        await this.promisedSetState({
            ...currentState,
        });
    }

    onChange = (value: any, option: any) => {
        this.setState({
            occupationName: option?.children,
            occupation: value,
        });
    }

    addUserEmployment = async () => {
        this.setState({
            formEmploymentBackground: true,
            tempJobEmployer: null,
            tempJobEmployerLocation: "",
            tempJobPosition: "",
            tempJobDescription: "",
            tempStartDate: dayjs("2000-01", 'YYYY-MM'),
            tempEndDate: dayjs("2003-01", 'YYYY-MM'),
            tempNotes: "",
            tempCurrentlyWorkHere: false,
            occupation: null,
            occupationName: "",
        })
    }

    confirmAddData = async () => {
        if (!this.state.tempJobPosition || !this.state.tempJobEmployer || !this.state.occupation) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            return;
        }

        const dataEmploymentBackground = [...this.state.dataEmploymentBackground];
        dataEmploymentBackground.push({
            employer: this.state.tempJobEmployer,
            employer_location: this.state.tempJobEmployerLocation,
            job_position: this.state.tempJobPosition,
            job_description: this.state.tempJobDescription,
            start_date: dayjs(this.state.tempStartDate).format("YYYY-MM-DD"),
            end_date: dayjs(this.state.tempEndDate).format("YYYY-MM-DD"),
            notes: this.state.tempNotes,
            currently_work_here: this.state.tempCurrentlyWorkHere,
            occupation: this.state.occupation,
            occupation_name: this.state.occupationName,
        });

        await this.promisedSetState({
            formEmploymentBackground: false,
            dataEmploymentBackground: dataEmploymentBackground,
        });

        await this.onUpdateVehicleLicense();
    }

    removeEntryCustom = async (i: number) => {
        const dataEmploymentBackground = [...this.state.dataEmploymentBackground];
        const newArray = [];
        let x = 0;
        for (const element of dataEmploymentBackground) {
            if (i !== x) {
                newArray.push(element);
            }
            x++;
        }
        await this.promisedSetState({
            dataEmploymentBackground: newArray
        });

        await this.onUpdateVehicleLicense();
    }

    onUpdateVehicleLicense = async () => {
        if (this.state.dataEmploymentBackground.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'mohon lengkapi minimal 1 Riwayat Kerja'
            });
            return;
        }

        const dataUpdate = {
            type: "update-biodata-employment-background",
            employment_background: this.state.dataEmploymentBackground
        }

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            fetching: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;

            this.setState({
                fetching: false,
                dataEmploymentBackground: (dataBio?.employment_background) ? dataBio?.employment_background : [],
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Button loading={this.state.submitting} onClick={event => this.addUserEmployment()} type="primary" icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Tambah Riwayat Kerja</Button>

                <Divider orientation="left" plain style={{marginTop: 50}}>
                    <span className={`divider-stock-purchase`}>Data Riwayat Kerja Anda</span>
                </Divider>

                <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                    <div style={this.state.dataEmploymentBackground?.length < 1 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>
                    <div style={this.state.dataEmploymentBackground?.length < 1 ? {display: 'none'} : {}}>
                        {this.state.dataEmploymentBackground.map((item: any, i: number) =>
                            <Card bordered={true} key={i} bodyStyle={{padding: 7}} style={item?.type === "family_register" ? {display: 'none'} : {}}>
                                <Row>
                                    <Col xl={{span: 22}} lg={{span: 22}} md={{span: 21}} sm={{span: 21}} xs={{span: 21}} style={{fontSize: "85%"}}>
                                        <span style={{paddingLeft: 10, color: "gray"}}>{item?.employer} - {item?.job_position}</span> <br/>
                                        <span style={{paddingLeft: 10}}>{item?.employer_location}</span> &nbsp; <span style={{paddingLeft: 10}}>{item?.start_date} - {item?.end_date}</span>
                                    </Col>
                                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 3}} sm={{span: 3}} xs={{span: 3}}>
                                        <Button loading={this.state.submitting} onClick={event => this.removeEntryCustom(i)} style={{marginTop: 10}} danger type="primary" icon={<DeleteOutlined/>}/>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                </div>

                <Modal
                    open={this.state.formEmploymentBackground}
                    title="Input Data Riwayat Kerja"
                    onCancel={() => this.setState({formEmploymentBackground: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({formEmploymentBackground: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <Form>
                        <Divider/>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Nama Perusahaan</i>
                            <Input value={this.state.tempJobEmployer} onChange={event => this.onFieldChange("tempJobEmployer", event.target.value)} placeholder="Nama Perusahaan"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Lokasi Perusahaan </i>
                            <Input value={this.state.tempJobEmployerLocation} onChange={event => this.onFieldChange("tempJobEmployerLocation", event.target.value)} placeholder="Lokasi Perusahaan"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Jenis Pekerjaan</i>
                            <Select
                                placeholder="Jenis Pekerjaan"
                                showSearch
                                optionFilterProp="children"
                                onChange={this.onChange}
                                value={this.props.value ?? this.state.occupation}
                            >
                                <Select.Option value="000001">belum/tidak bekerja</Select.Option>
                                <Select.Option value="000002">mengurus rumah tangga</Select.Option>
                                <Select.Option value="000003">pelajar/mahasiswa</Select.Option>
                                <Select.Option value="000004">pensiunan</Select.Option>
                                <Select.Option value="000005">pegawai negeri sipil</Select.Option>
                                <Select.Option value="000006">tentara nasional indonesia</Select.Option>
                                <Select.Option value="000007">kepolisian ri</Select.Option>
                                <Select.Option value="000008">perdagangan</Select.Option>
                                <Select.Option value="000009">petani/pekebun</Select.Option>
                                <Select.Option value="000010">peternak</Select.Option>
                                <Select.Option value="000011">nelayan/perikanan</Select.Option>
                                <Select.Option value="000012">industri</Select.Option>
                                <Select.Option value="000013">konstruksi</Select.Option>
                                <Select.Option value="000014">transportasi</Select.Option>
                                <Select.Option value="000015">karyawan swasta</Select.Option>
                                <Select.Option value="000016">karyawan bumn</Select.Option>
                                <Select.Option value="000017">karyawan bumd</Select.Option>
                                <Select.Option value="000018">karyawan honorer</Select.Option>
                                <Select.Option value="000019">buruh harian lepas</Select.Option>
                                <Select.Option value="000020">buruh tani/perkebunan</Select.Option>
                                <Select.Option value="000021">buruh nelayan/perikanan</Select.Option>
                                <Select.Option value="000022">buruh peternakan</Select.Option>
                                <Select.Option value="000023">pembantu rumah tangga</Select.Option>
                                <Select.Option value="000024">tukang cukur</Select.Option>
                                <Select.Option value="000025">tukang listrik</Select.Option>
                                <Select.Option value="000026">tukang batu</Select.Option>
                                <Select.Option value="000027">tukang kayu</Select.Option>
                                <Select.Option value="000028">tukang sol sepatu</Select.Option>
                                <Select.Option value="000029">tukang las/pandai besi</Select.Option>
                                <Select.Option value="000030">tukang jahit</Select.Option>
                                <Select.Option value="000031">tukang gigi</Select.Option>
                                <Select.Option value="000032">penata rias</Select.Option>
                                <Select.Option value="000033">penata busana</Select.Option>
                                <Select.Option value="000034">penata rambut</Select.Option>
                                <Select.Option value="000035">mekanik</Select.Option>
                                <Select.Option value="000036">seniman</Select.Option>
                                <Select.Option value="000037">tabib</Select.Option>
                                <Select.Option value="000038">paraji</Select.Option>
                                <Select.Option value="000039">perancang busana</Select.Option>
                                <Select.Option value="000040">penterjemah</Select.Option>
                                <Select.Option value="000041">imam mesjid</Select.Option>
                                <Select.Option value="000042">pendeta</Select.Option>
                                <Select.Option value="000043">pastor</Select.Option>
                                <Select.Option value="000044">wartawan</Select.Option>
                                <Select.Option value="000045">ustadz/mubaligh</Select.Option>
                                <Select.Option value="000046">juru masak</Select.Option>
                                <Select.Option value="000047">promotor acara</Select.Option>
                                <Select.Option value="000048">anggota dpr-ri</Select.Option>
                                <Select.Option value="000049">anggota dpd</Select.Option>
                                <Select.Option value="000050">anggota bpk</Select.Option>
                                <Select.Option value="000051">presiden</Select.Option>
                                <Select.Option value="000052">wakil presiden</Select.Option>
                                <Select.Option value="000053">anggota mahkamah konstitusi</Select.Option>
                                <Select.Option value="000054">anggota kabinet/kementerian</Select.Option>
                                <Select.Option value="000055">duta besar</Select.Option>
                                <Select.Option value="000056">gubernur</Select.Option>
                                <Select.Option value="000057">wakil gubernur</Select.Option>
                                <Select.Option value="000058">bupati</Select.Option>
                                <Select.Option value="000059">wakil bupati</Select.Option>
                                <Select.Option value="000060">walikota</Select.Option>
                                <Select.Option value="000061">wakil walikota</Select.Option>
                                <Select.Option value="000062">anggota dprd provinsi</Select.Option>
                                <Select.Option value="000063">anggota dprd kabupaten/kota</Select.Option>
                                <Select.Option value="000064">dosen</Select.Option>
                                <Select.Option value="000065">guru</Select.Option>
                                <Select.Option value="000066">pilot</Select.Option>
                                <Select.Option value="000067">pengacara</Select.Option>
                                <Select.Option value="000068">notaris</Select.Option>
                                <Select.Option value="000069">arsitek</Select.Option>
                                <Select.Option value="000070">akuntan</Select.Option>
                                <Select.Option value="000071">konsultan</Select.Option>
                                <Select.Option value="000072">dokter</Select.Option>
                                <Select.Option value="000073">bidan</Select.Option>
                                <Select.Option value="000074">perawat</Select.Option>
                                <Select.Option value="000075">apoteker</Select.Option>
                                <Select.Option value="000076">psikiater/psikolog</Select.Option>
                                <Select.Option value="000077">penyiar televisi</Select.Option>
                                <Select.Option value="000078">penyiar radio</Select.Option>
                                <Select.Option value="000079">pelaut</Select.Option>
                                <Select.Option value="000080">peneliti</Select.Option>
                                <Select.Option value="000081">sopir</Select.Option>
                                <Select.Option value="000082">pialang</Select.Option>
                                <Select.Option value="000083">paranormal</Select.Option>
                                <Select.Option value="000084">pedagang</Select.Option>
                                <Select.Option value="000085">perangkat desa</Select.Option>
                                <Select.Option value="000086">kepala desa</Select.Option>
                                <Select.Option value="000087">biarawati</Select.Option>
                                <Select.Option value="000088">wiraswasta</Select.Option>
                                <Select.Option value="000089">lainnya</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Jabatan / Posisi</i>
                            <Input value={this.state.tempJobPosition} onChange={event => this.onFieldChange("tempJobPosition", event.target.value)} placeholder="Jabatan / Posisi"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Deskripsi Pekerjaan</i>
                            <Input value={this.state.tempJobDescription} onChange={event => this.onFieldChange("tempJobDescription", event.target.value)} placeholder="Kota atau area Lokasi Sekolah / Institusi"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Awal Kerja</i>
                            <DatePicker
                                value={this.state.tempStartDate}
                                onChange={event => this.onFieldChange("tempStartDate", event)}
                                format={'YYYY-MM'} style={{width: "100%"}}
                                picker="month"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Akhir Kerja</i>
                            <DatePicker
                                value={this.state.tempEndDate}
                                onChange={event => this.onFieldChange("tempEndDate", event)}
                                format={'YYYY-MM'} style={{width: "100%"}}
                                picker="month"/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Catatan</i>
                            <TextArea showCount maxLength={200} value={this.state.tempNotes} onChange={event => this.onFieldChange("tempNotes", event.target.value)} placeholder="Catatan / informasi tambahan"/>
                        </Form.Item>
                        <Form.Item>
                            <Checkbox onChange={e => this.onFieldChange("tempCurrentlyWorkHere", e.target.checked)} checked={this.state.tempCurrentlyWorkHere}>
                                <i className={`text-small-grey-nopadding`}>Saya masih bekerja di tempat ini.</i>
                            </Checkbox>
                        </Form.Item>
                    </Form>
                    <Divider/>
                </Modal>
            </React.Fragment>
        )
            ;
    }
}

export default BiodataEmploymentBackground;
