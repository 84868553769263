const production = true;

/* CHERY */
const appData = {
    appVersion: "v-1.0.6",
    appProduction: production,
    appDefaultArea: "JAWABARAT",
    appCompanyCode: "chery",
    appCompanyName: "Chery",
    appCode: "chary",
    appAbbreviation: "CHR",
}

export const appProduction = appData.appProduction;
export const appCompanyCode = appData.appCompanyCode;
export const appCompanyName = appData.appCompanyName;
export const appCode = appData.appCode;
export const appAbbreviation = appData.appAbbreviation;
export const appVersion = appData.appVersion;
export const appDefaultArea = appData.appDefaultArea;
