import React from "react";
import Carousel from "antd/lib/carousel";
import "./Testimonial.css";
import TestimonialDetail from "./TestimonialDetail";

const dataPage0 = [
    {
        name: "Qiyee Chasya",
        testimonial: "keiritan konsumsi BBM nya, itu yg paling penting saat ini. congrats and welcome back Chery, I can't wait to see your improvement. Good luck Chery.",
    },
    {
        name: "Apuk Yorke",
        testimonial: "nilai plus: Garansi dan warranty wow, Hp dan torsi wow, Harga wow, Interior wow, Fitur dan teknologi wow, Safety wow, tunggu dealer nya ada deh..saya pasti beli..mesin 2.0 turbo super ganas"
    },
    {
        name: "Cayman",
        testimonial: "ga perlu alergi dengan Brand Tiongkok dengan catatan mereka bisa memberikan aftersales yang baik dan mutu serta kualitas yang sebanding dengan harganya. konsumen akan di untungkan dengan adanya persaingan brand2 besar. selama ini harga jual brand2 jepang menurut saya sedikit overpriced.",
    },
    {
        name: "Qiyee Chasya",
        testimonial: "keiritan konsumsi BBM nya, itu yg paling penting saat ini. congrats and welcome back Chery, I can't wait to see your improvement. Good luck Chery.",
    },
    {
        name: "Apuk Yorke",
        testimonial: "nilai plus: Garansi dan warranty wow, Hp dan torsi wow, Harga wow, Interior wow, Fitur dan teknologi wow, Safety wow, tunggu dealer nya ada deh..saya pasti beli..mesin 2.0 turbo super ganas"
    },
    {
        name: "Cayman",
        testimonial: "ga perlu alergi dengan Brand Tiongkok dengan catatan mereka bisa memberikan aftersales yang baik dan mutu serta kualitas yang sebanding dengan harganya. konsumen akan di untungkan dengan adanya persaingan brand2 besar. selama ini harga jual brand2 jepang menurut saya sedikit overpriced.",
    },
    {
        name: "Hi, 1626",
        testimonial: "Ada adaptive cruise control ges, mantep nih. kalo varian warna silvernya semacam warna silver di produk MG, bakal kelihatan lebih mewah lagi.",
    },
];

/*
const dataPage1 = [
    {
        name: "Qiyee Chasya",
        testimonial: "keiritan konsumsi BBM nya, itu yg paling penting saat ini. congrats and welcome back Chery, I can't wait to see your improvement. Good luck Chery.",
    },
    {
        name: "Apuk Yorke",
        testimonial: "nilai plus: Garansi dan warranty wow, Hp dan torsi wow, Harga wow, Interior wow, Fitur dan teknologi wow, Safety wow, tunggu dealer nya ada deh..saya pasti beli..mesin 2.0 turbo super ganas"
    },
    {
        name: "Cayman",
        testimonial: "ga perlu alergi dengan Brand Tiongkok dengan catatan mereka bisa memberikan aftersales yang baik dan mutu serta kualitas yang sebanding dengan harganya. konsumen akan di untungkan dengan adanya persaingan brand2 besar. selama ini harga jual brand2 jepang menurut saya sedikit overpriced.",
    },
];

const dataPage2 = [
    {
        name: "Henne Dwi Ananta",
        testimonial: "alhamdulillah saya sudah menjadi kel besar chery Indonesia, sangat worth it lah membeli mobil ini dibandingkan dgn rival spt pajero dan fortuner, semula saya juga ragu.. jgn2 spt dulu ternyata sangat beda jauh kualitas dan nyaman sekali, yg biasa pakai mobil2 eropa pasti kenyamanannya akan sama, jgn dibandingkan dgn produk2 jepang ya hrg mahal, teknologi dan kenyamanannya yg masih jauh dibandingkan dgn mobil ini...sangat puas dgn layanan servisnya, dan yg saya suka mobil ini full power, lari 200 km/jam ga goyah...Topps banget",
    },
    {
        name: "Hi, 1626",
        testimonial: "Ada adaptive cruise control ges, mantep nih. kalo varian warna silvernya semacam warna silver di produk MG, bakal kelihatan lebih mewah lagi.",
    },
    {
        name: "Anto Auto Channel",
        testimonial: "konsumen otomotif makin dimanjakan dengan berbagai brand yang bawa mobil berkualitas dengan harga sangat kompetitif.",
    },
];

const dataPage3 = [
    {
        name: "Ilham_77",
        testimonial: "mobil ini kayak seperti paket lengkap di kelasnya, fitur oke, mesin oke, design oke, meski secara pribadi saya kurang suka dg desaindepan tapi samping, belakang sampai atas maco banget.",
    },
    {
        name: "Xerses",
        testimonial: "dari semua brand dan mobil China so far, cuma ini yang bikin gue tertarik banget:))) Good job, Chery!",
    },
    {
        name: "Rizal Suwarno",
        testimonial: "Chery omoda 5 model keren fitur canggih dan melimpah dan harga kompetitif.... plus garansi mesin 10 tahun walau tak ada yang menjamin chery bisa bertahan hingga 10 tahun di Indonesia.",
    },
];

const dataPage4 = [
    {
        name: "di_mas",
        testimonial: "Gila si tigo lengkap banget fiturnya kombinasi antara wuling almaz dan santafe, untuk knok AC saya suka seperti piano hampir mirip dgn Lamborgini.. size bagian belakang lebih dr cukup untuk memuat barang\" yg sedang maupun besar, tambahan untuk hate tress kepala jok depan dan tengah ketika kita tidur bisa ditekuk kiri kanan supaya kepala kita nyaman tidak oleng pas tidur. Good job.",
    },
    {
        name: "Gara Funkei",
        testimonial: "gila bgt canggihnya..",
    },
    {
        name: "Arya Jaya Kusuma",
        testimonial: "calon mobil sukses telah hadir.. jika semua chenel yg sudah di tonton semuanya positif maka pembeli sudah 80% yakin kalo ini bagus.. tinggal tes drive dan aftersalesnya lagi. semoga team marketing Chery mau tukar tambah nantinya.",
    },
];

const dataPage5 = [
    {
        name: "Zey",
        testimonial: "gila ini mobil dewa, harga segitu, spesifikasi nya melebihi dari yg di pikirkan",
    },
    {
        name: "Selcuk Bayrakt",
        testimonial: "harus diakui keren, dan mereka menetapkan lompatan standard yg cukup tinggi. otomatis competitor juga akan mengikuti standard ini. keuntungan untuk konsumen industri otomotif.",
    },
    {
        name: "togar pohan",
        testimonial: "Tiggo 8 pro PHEV kalo jadi masuk & dirakit lokal sih top banget. minat banget buat beli.",
    },
];

*/

const Testimonial = () => {
    const PrevArrow = (props: any) => {
        const {onClick} = props;
        return (
            <div className="slick-arrow slick-prev" onClick={onClick}>
                <i className="fas fa-chevron-left"></i>
            </div>
        );
    }

    const NextArrow = (props: any) => {
        const {onClick} = props;
        return (
            <div className="slick-arrow slick-next" onClick={onClick}>
                <i className="fas fa-chevron-right"></i>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className={`footer-r-title`}>
                Testimonial
            </div>

            <div style={{marginLeft: 35, marginRight: 35}}>
                <Carousel
                    draggable={true}
                    arrows
                    prevArrow={<PrevArrow/>}
                    nextArrow={<NextArrow/>}
                >
                    {dataPage0.map((item: any, x: number) =>
                        <div key={x}>
                            <div className={`chosen-product`} style={{marginBottom: 10}}>
                                <TestimonialDetail name={item.name} testimony={item.testimonial}/>
                            </div>
                        </div>
                    )}

                    {/*<div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage1[0].name} testimony={dataPage1[0].testimonial}/>
                            <TestimonialDetail name={dataPage1[1].name} testimony={dataPage1[1].testimonial}/>
                            <TestimonialDetail name={dataPage1[2].name} testimony={dataPage1[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage2[0].name} testimony={dataPage2[0].testimonial}/>
                            <TestimonialDetail name={dataPage2[1].name} testimony={dataPage2[1].testimonial}/>
                            <TestimonialDetail name={dataPage2[2].name} testimony={dataPage2[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage3[0].name} testimony={dataPage3[0].testimonial}/>
                            <TestimonialDetail name={dataPage3[1].name} testimony={dataPage3[1].testimonial}/>
                            <TestimonialDetail name={dataPage3[2].name} testimony={dataPage3[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage4[0].name} testimony={dataPage4[0].testimonial}/>
                            <TestimonialDetail name={dataPage4[1].name} testimony={dataPage4[1].testimonial}/>
                            <TestimonialDetail name={dataPage4[2].name} testimony={dataPage4[2].testimonial}/>
                        </div>
                    </div>
                    <div>
                        <div className={`chosen-product`} style={{marginBottom: 10}}>
                            <TestimonialDetail name={dataPage5[0].name} testimony={dataPage5[0].testimonial}/>
                            <TestimonialDetail name={dataPage5[1].name} testimony={dataPage5[1].testimonial}/>
                            <TestimonialDetail name={dataPage5[2].name} testimony={dataPage5[2].testimonial}/>
                        </div>
                    </div>*/}

                </Carousel>
            </div>
        </React.Fragment>
    );
}

export default Testimonial;
