import React, {Component} from 'react';
import {SpkStates} from "./types/SpkTypes";
import {withRouter} from "../../hoc/withRouter";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {Alert, Breadcrumb, Col, message, Modal, Result, Row, Tooltip} from "antd";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import {offerPdfService} from "../../services/offer/OfferPdfService";

class SpkCredit extends Component<any, SpkStates> {
    private cookies = new Cookies();
    private readonly initState!: SpkStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const offerCode = match.params.offercode ?? "";

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            offerCode: offerCode,
            fetching: true,
            methodSelected: "all",
            modelSelected: "all",
            allowAgent: false,
            listModel: [],
            dataPromo: [],
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
            processing: false,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }


    componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor ESPK berhasil dicopy');
    }

    downloadPdf = async () => {
        this.setState({
            processing: true,
        });
        try {
            await offerPdfService.create(
                this.state.offerCode
            ).then(response => {
                this.setState({
                    processing: false,
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'amartachery-spk-' + this.state.offerCode + '.pdf');
                document.body.appendChild(link);
                link.click();

            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({
                processing: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta Chery</Breadcrumb.Item>
                            <Breadcrumb.Item>SPK Result</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>


                <Row className={`mt-20`} style={{backgroundColor: "#fff"}}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div className={`content-top-padding-20 height-100`}>
                            <Result
                                status="success"
                                title="Pengisian Data Sukses"
                                subTitle="Silakan tunggu konfirmasi dari kami untuk proses selanjutnya"
                            />
                            <br/>

                            Nomor Pengajuan (ESPK): {this.state.offerCode}
                            <Tooltip title="Click untuk copy nomor SPK" color={'cyan'}>
                                <CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.offerCode)}/>
                            </Tooltip> <br/> <br/>

                            <Alert
                                message="Informasi"
                                description={`Untuk memudahkan pelacakan dan informasi terkait transaksi anda, kami menghimbau anda untuk menyimpan nomor SPK. click untuk copy`}
                                type="info"
                                style={{cursor: "pointer"}}
                                showIcon
                                onClick={event => this.copyToClipboard(this.state.offerCode)}
                            /><br/>
                        </div>
                    </Col>
                </Row>

                <div style={{minHeight: 200}}/>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default withRouter(SpkCredit);