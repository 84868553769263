import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import {Breadcrumb, Image, Button, Col, Divider, Input, Modal, Row, Select, Spin, Upload} from "antd";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import FooterSection from "../../component/pageSection/FooterSection";
import PageHeader from "../../component/header/PageHeader";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import Form from "antd/lib/form";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import TextArea from "antd/es/input/TextArea";
import currencyFormat from "../../helper/currencyFormat";
import parse from 'html-react-parser';
import {offerApiServices} from "../../services/offer/OfferApiService";
import {PreBookingStates} from "./types/PreBookingTypes";
import Collapse from "antd/lib/collapse";
import CropIdCardImage from "../../component/image/CropIdCardImage";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";

const {Panel} = Collapse;

class PreBooking extends Component<any, PreBookingStates> {
    private cookies = new Cookies();
    private readonly initState!: PreBookingStates;

    constructor(props: any) {
        super(props);

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
            listPreBooking: [],
            loadingFetch: true,
            infoPreBooking: {
                code: "",
                caption: "",
                amount: 0,
                notes: "",
            },
            submitLoading: false,
            freezeStatus: false,
            name: "",
            phoneNumber: "",
            preBookingSelected: null,
            agentCode: "",
            notes: "",
            idCardNumber: "",
            idCardImageUrl: "",
            base64IdCardOwner: "",
            showModalUploadIdCardOwner: false,
            showOldUploadRawImage: false,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onFieldChange = async <T extends keyof Pick<any, "idCardNumber" | "notes" | "agentCode" | "name" | "phoneNumber" | "preBookingSelected">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "name":
                currentState.name = value?.toUpperCase()?.trim();
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "preBookingSelected":
                currentState.preBookingSelected = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
            case "notes":
                currentState.notes = value;
                break;
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
        }
        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        await this.promisedSetState({
            ...currentState,
        });

        if (target === 'preBookingSelected') {
            const picked = this.state.listPreBooking.find((o: { code: string; }) => o.code === value);
            await this.promisedSetState({
                infoPreBooking: {
                    code: picked.code,
                    caption: picked.caption,
                    amount: picked.amount,
                    notes: picked.notes,
                }
            });
        }
    }

    fetchData = async () => {
        try {
            const listPreBooking = await trimitraCatalogServices.getPreBooking();
            this.setState({
                loadingFetch: false,
                listPreBooking: listPreBooking?.data?.data,
            });
        } catch (e) {
            this.setState({
                loadingFetch: false,
                listPreBooking: [],
            });
        }
    }

    onSubmit = async () => {
        let warnNotice = "";

        if (!this.state.phoneNumber) {
            warnNotice += "Nomor Telpon, ";
        }

        if (warnNotice !== "") {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }

        const dataPreBooking = {
            agent_code: this.state.agentCode,
            area: this.state.areaCode,
            company: "chery",
            source: "amartachery.com",
            name: this.state.name,
            phone_number: this.state.phoneNumber,
            id_card_number: this.state.idCardNumber,
            id_card_image: this.state.idCardImageUrl,
            prebooking_code: this.state.preBookingSelected,
            prebooking_amount: this.state.infoPreBooking.amount,
            notes: this.state.notes,
        }

        this.setState({
            submitLoading: true,
            freezeStatus: true,
        });

        try {
            await offerApiServices.createPreBooking(dataPreBooking).then(successData => {
                window.location.href = `/booking/${successData.data.data.prebooking_code}`;
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                submitLoading: false,
                freezeStatus: false
            });
        }
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                if (type === "SIGNATURE") {
                    await this.promisedSetState({
                        signatureImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadIdCardOwner: false,
                    showModalUploadSignature: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.setState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            });
        }
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
        this.fetchData();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={true}/>
                <Row className={`pt-100`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amarta Chery</Breadcrumb.Item>
                            <Breadcrumb.Item>Booking</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row className={`p-10 mt-20`} style={{backgroundColor: "#f8f8f8"}}/>

                <div style={this.state.loadingFetch ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <Row style={!this.state.loadingFetch ? {backgroundColor: "#fff"} : {display: 'none'}} className={`p-20 mt-20`}>
                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col style={{padding: 20}} xxl={{span: 14}} xl={{span: 14}} lg={{span: 14}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Form.Item>
                                    <i className={`text-small-grey`}>Tipe Booking <b style={{color: "#e52a2a"}}>*</b></i>
                                    <Select placeholder={`Pilih Tipe PreBooking`} onChange={value => this.onFieldChange("preBookingSelected", value)} value={this.state.preBookingSelected} style={{width: '100%'}}>
                                        {this.state.listPreBooking.map((item: any, i: number) =>
                                            <Select.Option key={i} value={item.code}>{item.code} - {item.caption}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey`}>Nama <b style={{color: "#e52a2a"}}>*</b> </i>
                                    <Input value={this.state.name} placeholder="Masukan nama calon pembeli" onChange={event => this.onFieldChange("name", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Nomor Telpon <b style={{color: "#e52a2a"}}>*</b></i>
                                    <Input
                                        value={this.state.phoneNumber}
                                        maxLength={17}
                                        placeholder="Format: 08xxxxxxxx"
                                        onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Nomor KTP</i>
                                    <Input
                                        maxLength={16}
                                        value={this.state.idCardNumber}
                                        placeholder="Nomor KTP"
                                        onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <div className={`card-box-shadow`}>
                                        <Row>
                                            <Col span={24}>
                                                <IdcardOutlined className={`icon-title-info`}/>
                                                <b className={`text-title-info`}>KTP</b>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingTop: 10}}>
                                            <Col span={23} offset={1}>
                                                <Row>
                                                    <Col span={12}>
                                                        <div className="font-old-grey-100">
                                                            <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                            <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.name} </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        {this.state.idCardImageUrl
                                                            ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.idCardImageUrl}/></div>
                                                            : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'/assets/img/uploadImage.png'}/></div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                    {this.state.idCardImageUrl
                                                        ? <span>Update Gambar KTP</span>
                                                        : <span>Lengkapi Gambar KTP</span>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey`}>Kode Agen <b style={{color: "#e52a2a"}}>*</b> </i>
                                    <Input value={this.state.agentCode} placeholder="Masukan kode agen" onChange={event => this.onFieldChange("agentCode", event.target.value)}/>
                                </Form.Item>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Catatan</i>
                                    <TextArea showCount maxLength={200} value={this.state.notes} onChange={event => this.onFieldChange("notes", event.target.value)} placeholder="opsional, dapat diisi jika memiliki catatan atau kondisi khusus"/>
                                </Form.Item>

                                <Divider/>

                                <Button onClick={() => this.onSubmit()} loading={this.state.submitLoading} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Submit Data Booking</Button>

                            </Col>
                            <Col style={{padding: 20}} xxl={{span: 9}} xl={{span: 9}} lg={{span: 9}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <span className={`text-small-grey-nopadding`}>{this.state.infoPreBooking.code}</span><br/>
                                <span>{this.state.infoPreBooking.caption}</span><br/><br/>
                                <span style={this.state.infoPreBooking.amount ? {} : {display: 'none'}}><span className={`text-small-grey-nopadding`}>Nominal:</span> <br/> {currencyFormat(this.state.infoPreBooking.amount)}</span><br/><br/>
                                <span style={this.state.infoPreBooking.notes ? {} : {display: 'none'}}><span className={`text-small-grey-nopadding`}>Kebijakan PreBooking:</span> <br/> {parse(this.state.infoPreBooking.notes ?? "")}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div style={{minHeight: 100}}/>
                <FooterSection/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {background: "#f33232", border: "#f33232", color: "#fff"} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", marginLeft: 25}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{id_card_number: this.state.idCardNumber}}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(PreBooking);
