import React, {Component} from 'react';
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import {Layout, Spin, Card, Empty, Row} from "antd";
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import {vacancyApiService} from "../../services/amartaVip/VacancyApiService";
import Badge from "antd/lib/badge";
import {appVersion} from "../../config/appDataConfig/appConfig";
import FooterSection from "../../component/pageSection/FooterSection";

export interface MySubmissionProps {
}

export interface MySubmissionStates extends MySubmissionFields {
    showDrawerMenu: boolean;
}

export interface MySubmissionFields {
    name: string;
    email: string;
    phoneNumber: string;
    userType: string;
    userCode: string;
    token: string;
    fetchingApply: boolean,
    dataApply: any,
}

class MySubmission extends Component<MySubmissionProps & any, MySubmissionStates> {
    private cookies = new Cookies();

    constructor(props: MySubmissionProps & any) {
        super(props);

        this.state = {
            showDrawerMenu: false,
            name: Buffer.from(this.cookies.get('_tc-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_tc-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_tc-p'), 'base64').toString(),
            userType: Buffer.from(this.cookies.get('_tc-ut'), 'base64').toString() ?? "",
            userCode: Buffer.from(this.cookies.get('_tc-uc'), 'base64').toString() ?? "",
            token: this.cookies.get('_tc-t'),
            fetchingApply: true,
            dataApply: [],
        }
    }

    getListSubmission = async () => {
        try {
            await vacancyApiService.setToken(this.state.token);
            const fetchResult = await vacancyApiService.getUserApply();
            const dataApply = fetchResult?.data?.data;
            this.setState({
                fetchingApply: false,
                dataApply: dataApply,
            });
        } catch (e) {
            this.setState({
                fetchingApply: false,
                dataApply: [],
            });
        }
    }

    componentDidMount() {
        this.getListSubmission();
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"Pengajuan"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '79vh', backgroundColor: "#fff", padding: "2vh"}}>

                            <div style={this.state.fetchingApply ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>

                            <div style={!this.state.fetchingApply ? {} : {display: 'none'}}>
                                {this.state.dataApply.map((item: any, x: number) =>
                                    <Badge.Ribbon key={x} text={(item?.vacancy_status === "OPEN" ? "Dalam Proses" : " ")} color={(item?.vacancy_status === "OPEN" ? "blue" : "magenta")}>
                                        <Card hoverable size="small" style={{width: "100%"}}>
                                            <div><i className={`text-small-grey-nopadding`}>Kode Aplikasi:</i> {item?.vacancy_code}</div>
                                            <div><i className={`text-small-grey-nopadding`}>Role Aplikasi:</i> {item?.role_name}</div>
                                            <div><i className={`text-small-grey-nopadding`}>Organisasi:</i> {item?.organization}</div>
                                            <div><i className={`text-small-grey-nopadding`}>Tanggal Aplikasi:</i> {item?.created_time?.slice(0, 16)?.replace("T", " ")}</div>
                                        </Card><br/>
                                    </Badge.Ribbon>
                                )}
                                <div style={this.state.dataApply.length === 0 ? {} : {display: 'none'}}>
                                    <Empty/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Layout>
                <FooterSection version={appVersion}/>
            </React.Fragment>
        );
    }
}

export default withAuth(MySubmission);
