import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout} from "antd";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import Col from "antd/lib/col";
import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import Form from "antd/lib/form";
import Tabs from "antd/lib/tabs";
import Row from "antd/lib/row";
import {MyBiodataStates} from "./types/MyBiodataTypes";
import Spin from "antd/lib/spin";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import BiodataIdCard from "../../component/biodata/BiodataIdCard";
import FooterSection from "../../component/pageSection/FooterSection";
import BiodataSocialMedia from "../../component/biodata/BiodataSocialMedia";
import BiodataVehicleLicense from "../../component/biodata/BiodataVehicleLicense";
import BiodataEducationalBackground from "../../component/biodata/BiodataEducationalBackground";
import BiodataEmploymentBackground from "../../component/biodata/BiodataEmploymentBackground";
import {appVersion} from "../../config/appDataConfig/appConfig";
import BiodataCv from "../../component/biodata/BiodataCv";
// import BiodataFamily from "../../component/biodata/BiodataFamily";
// import BiodataCurrentAddress from "../../component/biodata/BiodataCurrentAddress";

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

export interface MyBiodataProps {
}

class MyBiodata extends Component<MyBiodataProps & any, MyBiodataStates> {
    private cookies = new Cookies();

    constructor(props: MyBiodataProps & any) {
        super(props);

        this.state = {
            submitting: false,
            freezeStatus: false,
            processing: false,
            profileData: {},

            name: Buffer.from(this.cookies.get('_tc-n'), 'base64').toString(),
            email: Buffer.from(this.cookies.get('_tc-e'), 'base64').toString(),
            phoneNumber: Buffer.from(this.cookies.get('_tc-p'), 'base64').toString(),
            token: this.cookies.get('_tc-t'),

            showOldUploadRawImage: false,

            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",

            familyCardImageUrl: "",
            showModalUploadFamilyCard: false,
            base64FamilyCard: "",

            showDrawerMenu: false,
            idCardNumber: "",
            idCardName: "",
            birthPlace: "",
            birthDate: dayjs("2022-01-01", dateFormat),
            fullAddress: "",
            provinceCode: null,
            provinceName: null,
            cityCode: null,
            cityName: null,
            districtCode: null,
            districtName: null,
            subdistrictCode: null,
            subdistrictName: null,
            postalCode: "",
            hamlet: "",
            neighbourhood: "",

            familyCardNumber: "",

            fullAddressCurrent: "",
            provinceCodeCurrent: null,
            provinceNameCurrent: null,
            cityCodeCurrent: null,
            cityNameCurrent: null,
            districtCodeCurrent: null,
            districtNameCurrent: null,
            subdistrictCodeCurrent: null,
            subdistrictNameCurrent: null,
            postalCodeCurrent: "",
            hamletCurrent: "",
            neighbourhoodCurrent: "",
        }
    }

    async componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>

                <Layout className="layout">
                    <Row style={{backgroundColor: '#fff', paddingBottom: 5, borderBottom: "#f0f2f5 2px solid"}}>
                        <Col xl={{span: 20, offset: 2}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                            <MyProfileLoginHeader title={"My Biodata"}/>
                        </Col>
                    </Row>
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{backgroundColor: "#fff", padding: "2vh"}}>
                            <div style={this.state.processing ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </div>
                            <div style={!this.state.processing ? {} : {display: 'none'}}>
                                <Form layout="vertical">
                                    <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                        <i>*Data KTP digunakan untuk memenuhi syarat pengajuan aplikasi. Beberapa lowongan pekerjaan membutuhkan data tambahan seperti SIM, Sosial Media, Pendidikan dan Pengalaman Kerja</i>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                            {
                                                label: `Data KTP`,
                                                key: '1',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataIdCard/>
                                                    </React.Fragment>
                                            },
                                            {
                                                label: `Data SIM`,
                                                key: '2',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataVehicleLicense/>
                                                    </React.Fragment>
                                            },
                                            {
                                                label: `Sosial Media`,
                                                key: '3',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataSocialMedia/>
                                                    </React.Fragment>
                                            },
                                            {
                                                label: `Pendidikan`,
                                                key: '4',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataEducationalBackground/>
                                                    </React.Fragment>
                                            },
                                            {
                                                label: `Pengalaman Kerja`,
                                                key: '5',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataEmploymentBackground/>
                                                    </React.Fragment>
                                            },
                                            {
                                                label: `Upload CV`,
                                                key: '6',
                                                children:
                                                    <React.Fragment>
                                                        <BiodataCv/>
                                                    </React.Fragment>
                                            },



                                            // {
                                            //     label: `Data KK`,
                                            //     key: 'a',
                                            //     children:
                                            //         <React.Fragment>
                                            //             <BiodataFamily/>
                                            //         </React.Fragment>,
                                            // },
                                            // {
                                            //     label: `Alamat Saat Ini`,
                                            //     key: 'b',
                                            //     children:
                                            //         <React.Fragment>
                                            //             <BiodataCurrentAddress/>
                                            //         </React.Fragment>,
                                            // },
                                        ]}
                                    />
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Layout>
                <FooterSection version={appVersion}/>
            </React.Fragment>
        );
    }
}

export default withAuth(MyBiodata);
