import React, {Component} from "react";
import {Button, Card, Row} from "antd";
import Col from "antd/lib/col";
import {Link} from "react-router-dom";
import currencyMillionFormat from "../../helper/currencyMillionFormat";

class CardProduct extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    render() {
        let cardName = "";
        let url = "products";

        if (this.props.data?.entity_type === "variant") {
            cardName = this.props.data?.variant_name;
            url = "variant/" + this.props.data?.variant_code;
        }
        if (this.props.data?.entity_type === "model") {
            cardName = this.props.data?.model_name;
        }
        if (this.props.data?.entity_type === "catalog") {
            cardName = this.props.data?.catalog_name;
        }

        return (
            <React.Fragment>
                <Link to={`/` + url}>
                    <Card
                        hoverable
                        style={{width: "100%"}}
                        cover={<img alt="vacancy-logo" src={this.props.data?.url_image}/>}
                    >
                        <Row>
                            <Col span={24}>
                                <span className={`card-product-title`}>{cardName?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span><br/>

                                <div style={this.props.data?.entity_type === "variant" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <span style={this.props.data?.price === 0 ? {} : {display: 'none'}} className={`card-product-price`}>
                                        Not Available
                                    </span>
                                    <span style={this.props.data?.price !== 0 ? {} : {display: 'none'}} className={`card-product-price`}>
                                        {currencyMillionFormat(this.props.data?.price) ?? "-"}
                                    </span>
                                </div>

                                <div style={this.props.data?.entity_type === "model" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <span className={`card-product-price`}>
                                        {currencyMillionFormat(this.props.data?.price_min) ?? "-"} - {currencyMillionFormat(this.props.data?.price_max) ?? "-"}
                                    </span>
                                </div>

                                <div style={this.props.data?.entity_type === "catalog" ? {} : {display: 'none'}} className={`pt-10`}>
                                    <Button danger>Lihat Katalog</Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Link>
            </React.Fragment>
        );
    }
}

export default CardProduct;
