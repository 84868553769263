import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import {PurchasePlanStates} from "./types/PurchasePlanTypes";
import {appDefaultArea} from "../../config/appDataConfig/appConfig";
import Cookies from "universal-cookie";
import CurrencyInput from "react-currency-input-field";
import Typography from "antd/lib/typography";
import PageHeader from "../../component/header/PageHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {Col, Image, Row, Spin, Input, Button, Checkbox, Modal, Upload, Card, Radio, Tooltip, Tag} from "antd";
import Form from "antd/lib/form";
import {Divider} from "antd";
import AreaProvinceSelect from "../../component/area/AreaProvinceSelect";
import AreaCitySelect from "../../component/area/AreaCitySelect";
import AreaDistrictSelect from "../../component/area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../../component/area/AreaSubdistrictSelect";
import TextArea from "antd/es/input/TextArea";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import CropIdCardImage from "../../component/image/CropIdCardImage";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import Collapse from "antd/lib/collapse";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import ListPromoModal from "../../component/promo/ListPromoModal";
import {Link} from "react-router-dom";
import {offerApiServices} from "../../services/offer/OfferApiService";
import SignatureCanvas from 'react-signature-canvas'
import currencyFormat from "../../helper/currencyFormat";
import FincoSelect from "../../component/submission/FincoSelect";
import {leadService} from "../../services/trimitraIdeal/LeadServive";

const {Panel} = Collapse;

class PurchasePlant extends Component<any, PurchasePlanStates> {
    private cookies = new Cookies();
    private readonly initState!: PurchasePlanStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const variantCode = match.params.param;
        const colorCode = match.params.param2;

        if (!this.cookies.get('_amc-a')) {
            this.cookies.set('_amc-a', appDefaultArea);
        }

        this.initState = {
            isDesktop: false,
            variantCode: variantCode,
            colorCode: colorCode,
            fetching: true,
            dataVariant: {},
            listVariantColor: [],
            dataVariantColor: {},
            submitButton: false,
            areaCode: (this.cookies.get('_amc-a')) ? this.cookies.get('_amc-a') : appDefaultArea,
            purchaseMethod: "cash",
            fullName: "",
            idCardNumber: "",
            phoneNumber: "",
            email: "",
            agentCode: "",
            customerProvinceCode: "",
            customerProvinceName: null,
            customerCityCode: "",
            customerCityName: null,
            customerDistrictCode: "",
            customerDistrictName: null,
            customerSubdistrictCode: "",
            customerSubdistrictName: null,
            customerPostalCode: "",
            customerHamlet: "",
            customerNeighbourhood: "",
            customerAddress: "",
            takeInDealer: false,
            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,
            showOldUploadRawImage: false,
            base64IdCardOwner: "",
            freezeStatus: false,
            signatureImageUrl: "",
            showModalUploadSignature: false,
            modalPromoShow: false,
            usedPromoData: {},
            amartaVipUid: "",
            alternativeColor: {
                code: "SAME_AS_ORDER",
                name: "warna sesuai pesanan"
            },
            tenorList: [11, 17, 23, 29, 35],
            tenor: 11,
            downPayment: 0,
            installmentAmount: 0,
            minimumDp: 0,
            maximumDp: 0,
            showCreditSimulation: false,
            dataCreditSimulation: {
                rangeDown: 0,
                rangeUp: 0,
                tenorFirst: 1,
                tenorLast: 11,
                tenorDateFirst: 1,
                tenorDateLast: 11,
            },
            fetchingCreditSimulation: false,
            fincoChosenCode: "GLOBAL",
            fincoChosenName: "PENAWARAN TERBAIK",

            amartaVipIsLead: false,
            fetchAgentCodeFromLeadNumber: false,
            disableAgentCode: false,
            prebookingCode: "",
            depositCode: "",
        }
        this.state = {...this.initState}
    }

    sigPad: any = {}
    clear = () => {
        this.sigPad.clear()
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize);
        this.handleResize();

        this.fetchVariant();
    }

    async fetchVariant() {
        try {
            const dataDetail = await trimitraCatalogServices.getCatalogVariant({
                areaCode: this.state.areaCode,
                variantCode: this.state.variantCode,
            });

            const dataVariant = dataDetail?.data?.meta;
            const dataColor = dataDetail?.data?.data;

            const picked = dataColor.find((o: { color_code: string; }) => o.color_code === this.state.colorCode);

            this.setState({
                fetching: false,
                dataVariant: dataVariant,
                listVariantColor: dataColor,
                dataVariantColor: picked,
                downPayment: Math.round(15 / 100 * (picked?.price ?? 0) / 1000000) * 1000000
            });
        } catch (e) {
            this.setState({
                fetching: false,
                dataVariant: {},
                listVariantColor: [],
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onAreaChange = async (data: any) => {
        this.cookies.set('_amc-a', data.city_group);
        await this.promisedSetState({
            areaCode: data.city_group,
            submitButton: true,
        });

        window.location.reload();
    }

    phoneNumberBlur = async () => {
        this.setState({
            fetchAgentCodeFromLeadNumber: true,
            disableAgentCode: false,
            submitButton: true,
            agentCode: "",
            amartaVipIsLead: false,
        });
        try {
            await leadService.setToken();
            const fetchResult = await leadService.getGlobalNotesByPhoneNumber({
                organizationCode: "amartachery",
                phoneNumber: (this.state.phoneNumber.substring(0, 2) === "08") ? this.state.phoneNumber.replace(/^.{2}/g, '628') : this.state.phoneNumber,
            });

            if (fetchResult?.data?.success?.data?.length > 0) {
                this.setState({
                    amartaVipIsLead: true,
                    fetchAgentCodeFromLeadNumber: false,
                    disableAgentCode: true,
                    submitButton: false,
                    agentCode: fetchResult?.data?.success?.data[0]?.agentCode,
                });
            } else {
                this.setState({
                    fetchAgentCodeFromLeadNumber: false,
                    disableAgentCode: false,
                    submitButton: false,
                });
            }
        } catch (e) {
            this.setState({
                fetchAgentCodeFromLeadNumber: false,
                disableAgentCode: false,
                submitButton: false,
            });
        }
    }

    onFieldChange = <T extends keyof Pick<any, "prebookingCode" | "depositCode" | "installmentAmount" | "tenor" | "downPayment" | "fullName" | "purchaseMethod" | "idCardNumber" | "phoneNumber" | "email" | "agentCode" | "customerPostalCode" | "customerHamlet" | "customerNeighbourhood" | "customerAddress" | "takeInDealer">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "prebookingCode":
                currentState.prebookingCode = value;
                break;
            case "depositCode":
                currentState.depositCode = value;
                break;
            case "installmentAmount":
                currentState.installmentAmount = value;
                break;
            case "tenor":
                currentState.tenor = value;
                break;
            case "downPayment":
                currentState.downPayment = value;
                break;
            case "fullName":
                currentState.fullName = value;
                break;
            case "purchaseMethod":
                currentState.purchaseMethod = value.target.value;
                break;
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "email":
                currentState.email = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
            case "customerPostalCode":
                currentState.customerPostalCode = value;
                break;
            case "customerHamlet":
                currentState.customerHamlet = value;
                break;
            case "customerNeighbourhood":
                currentState.customerNeighbourhood = value;
                break;
            case "customerAddress":
                currentState.customerAddress = value?.toUpperCase();
                break;
            case "takeInDealer":
                currentState.takeInDealer = value;
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            customerProvinceCode: data.value,
            customerProvinceName: data.children,
            customerCityCode: "",
            customerCityName: null,
            customerDistrictCode: "",
            customerDistrictName: null,
            customerSubdistrictCode: "",
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            customerCityCode: data.value,
            customerCityName: data.children,
            customerDistrictCode: "",
            customerDistrictName: null,
            customerSubdistrictCode: "",
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            customerDistrictCode: data.value,
            customerDistrictName: data.children,
            customerSubdistrictCode: "",
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            customerSubdistrictCode: data.value,
            customerSubdistrictName: data.children,
            customerPostalCode: data.postalCode
        });
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    clickUpdateSignatureImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadSignature: true});
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    saveSignatureData = async () => {
        if (this.sigPad.getTrimmedCanvas().toDataURL('image/png') === 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC') {
            Modal.error({title: 'Error', content: "Tanda tangan dibutuhkan"});
            return;
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            document_type: 'signature',
            source: 'amartachery.com',
            image_data: this.sigPad.getSignaturePad().toDataURL('image/png'),
        }

        await this.uploadImageAndUpdateState("SIGNATURE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                if (type === "SIGNATURE") {
                    await this.promisedSetState({
                        signatureImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadIdCardOwner: false,
                    showModalUploadSignature: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.setState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            });
        }
    }

    uploadSignatureChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                signatureImageUrl: newImgLocation,
                showModalUploadSignature: false,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                signatureImageUrl: "",
            });
        }
    }

    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0');
    }

    validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    onPromoChange = (data: any) => {
        this.setState({
            usedPromoData: data,
            modalPromoShow: false,
        });
    }

    createRandomString = async (length: number) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            result += chars[randomIndex];
        }
        return result;
    }

    onSubmit = async () => {
        let warnNotice = "";
        // if(this.state.fetchAgentCodeFromLeadNumber){
        //     warnNotice += "Mohon tunggu beberapa saat, system sedang melakukan pengcekan lead agen, ";
        // }

        if (!this.state.phoneNumber) {
            warnNotice += "Nomor Telpon, ";
        }
        if (!this.validateEmail(this.state.email)) {
            warnNotice += "email tidak valid, ";
        }
        if (!this.state.idCardImageUrl) {
            warnNotice += "Upload Gambar KTP, ";
        }
        if (this.state.idCardNumber.length !== 16) {
            warnNotice += "Nomor KTP tidak valid, ";
        }
        if (!this.state.customerProvinceCode) {
            warnNotice += "Provinsi, ";
        }
        if (!this.state.customerCityCode) {
            warnNotice += "Kota, ";
        }
        if (!this.state.customerDistrictCode) {
            warnNotice += "Kecamatan, ";
        }
        if (!this.state.customerSubdistrictCode) {
            warnNotice += "Kelurahan, ";
        }
        if (!this.state.customerPostalCode) {
            warnNotice += "Kode Pos, ";
        }
        if (!this.state.customerAddress) {
            warnNotice += "Alamat Identitas, ";
        }
        if (!this.state.fullName) {
            warnNotice += "Nama Lengkap, ";
        }
        if (!this.state.phoneNumber) {
            warnNotice += "Nomor Telpon, ";
        }
        if (!this.state.signatureImageUrl) {
            warnNotice += "Upload Gambar Signature (Tanda Tangan), ";
        }

        if (this.state.purchaseMethod === 'credit') {
            if (!this.state.tenor) {
                warnNotice += "tenor, ";
            }
            if (!this.state.downPayment) {
                warnNotice += "down payment, ";
            }
            if (!this.state.fincoChosenCode) {
                warnNotice += "leasing, ";
            }
            if (!this.state.installmentAmount) {
                warnNotice += "cicilan, ";
            }
        }

        /* PREBOOKING-COMMENT */
        // if (!this.state.prebookingCode) {
        //     warnNotice += "Booking Code, ";
        // }
        // if (!this.state.depositCode) {
        //     warnNotice += "Deposit Code, ";
        // }

        if (warnNotice !== "") {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }

        const dataOffer = {

            /* PREBOOKING-COMMENT */
            // prebooking_offer: true,
            // prebooking_code: this.state.prebookingCode,
            // deposit_code: this.state.depositCode,

            amarta_vip_uid: "",
            amarta_vip_is_lead: this.state.amartaVipIsLead,
            agent_code: this.state.agentCode,
            company: "chery",
            source: "amartachery.com",
            purchase_method: this.state.purchaseMethod,
            area: this.state.areaCode,
            take_vehicle_in_dealer: this.state.takeInDealer,
            phone_number_owner: this.state.phoneNumber,
            phone_number_wa_available_owner: false,
            promo_codes: (this.state.usedPromoData?.promoCode) ? [this.state.usedPromoData?.promoCode] : [],
            offer_broker: false,
            notes: "",
            email_owner: this.state.email,
            signature_owner: {
                signature_image: this.state.signatureImageUrl,
                signature_number: this.state.idCardNumber
            },
            id_card_owner: {
                full_name: this.state.fullName?.trim(),
                id_card_number: this.state.idCardNumber,
                id_card_image: this.state.idCardImageUrl,
                full_address: this.state.customerAddress,
            },
            address_owner: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_name: this.state.customerDistrictName,
                district_code: this.state.customerDistrictCode,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode,
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            vehicle: {
                brand_name: this.state.dataVariantColor.brand_name,
                brand_uuid: this.state.dataVariantColor.brand_uuid,
                model_name: this.state.dataVariantColor.model_name,
                model_uuid: this.state.dataVariantColor.model_uuid,
                variant_name: this.state.dataVariantColor.variant_name,
                variant_uuid: this.state.dataVariantColor.variant_uuid,
                variant_code: this.state.dataVariantColor.variant_code,
                color: this.state.dataVariantColor.color_name,
                color_code: this.state.dataVariantColor.color_code,
                alternative_color: {
                    code: "SAME_AS_ORDER",
                    name: "warna sesuai pesanan"
                }
            },
            credit: {
                otr: this.state.dataVariantColor?.price,
                dp_amount: this.state.downPayment,
                tenor: this.state.tenor,
                installment_amount: this.state.installmentAmount,
                finco_code: this.state.fincoChosenCode,
                finco_branch: "",
                finco_name: this.state.fincoChosenName,
                finco_comission: 1,
                po_code: await this.createRandomString(16),
            },
        }

        this.setState({
            submitButton: true,
            freezeStatus: true,
        });

        try {
            await offerApiServices.createOffer(
                dataOffer
            ).then(successData => {
                window.location.href = `/spk/${successData.data.data.offer_code}`;
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                submitButton: false,
                freezeStatus: false
            });
        }
    }

    changeDpPercentage = (data: number) => {
        let dpChosen = data / 100 * (this.state.dataVariantColor?.price ?? 0);
        dpChosen = Math.round(dpChosen / 1000000) * 1000000;
        this.setState({
            downPayment: dpChosen
        });
    }

    blurDownPayment = () => {
        let dpChosen = Math.round(this.state.downPayment / 1000000) * 1000000;
        this.setState({
            downPayment: dpChosen
        });
    }

    blurInstallmentAmount = () => {
        let dpChosen = Math.round(this.state.installmentAmount / 100000) * 100000;
        this.setState({
            installmentAmount: dpChosen
        });
    }

    blurTenor = () => {
        if (Number(this.state.tenor) > 100) {
            this.setState({
                tenor: 100
            });
        }
    }

    onFincoSelectChange = async (data: any) => {
        this.setState({
            fincoChosenCode: data.value,
            fincoChosenName: data.children,
        })
    }

    render() {
        return (
            <React.Fragment>
                <PageHeader black={true} onAreaChangeProps={this.onAreaChange} areaCode={this.state.areaCode} isDesktop={this.state.isDesktop}/>

                <div style={this.state.fetching ? {textAlign: "center", padding: 70, minHeight: "70vh"} : {display: 'none'}}>
                    <Spin style={{marginTop: 100}} size={'large'} tip="Loading..."/>
                </div>
                <div style={this.state.fetching ? {display: 'none'} : {}}>
                    <Row style={{padding: 20, paddingTop: 100}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>

                            <div style={this.state.dataVariantColor?.price ? {display: 'none'} : {}}>
                                <div style={{marginTop: 200}}/>
                                <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7}}>Variant Belum Tersedia di Wilayah ini</div>
                                <div style={{textAlign: "center", fontSize: "100%", marginBottom: 20, color: "gray"}}>Kami akan segera mengahdirkanya di wilayah anda.</div>
                                <div style={{marginTop: 200}}/>
                            </div>

                            <div style={!this.state.dataVariantColor?.price ? {display: 'none'} : {}}>
                                <div>
                                    <div style={{textAlign: "center", fontSize: "170%", marginBottom: 7}}>Form SPK Kendaraan</div>
                                    <div style={{textAlign: "center", fontSize: "100%", marginBottom: 20, color: "gray"}}>Silakan isi data diri Anda pada form di bawah ini</div>
                                </div>

                                <Row>
                                    <Col style={{paddingTop: 50}} xxl={{span: 7}} xl={{span: 7}} lg={{span: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <Row>
                                            <Col span={16} style={{paddingRight: 20}}>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.model_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.variant_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>{this.state.dataVariantColor?.color_name}</Col>
                                                </Row>
                                                <Row style={{paddingTop: 5}}>
                                                    <Col span={24} className={`text-small-grey-nopadding`}>OTR: {currencyFormat(this.state.dataVariantColor?.price)}</Col>
                                                </Row>
                                                <Link to={`/variant/${this.state.variantCode}`}>
                                                    <Button style={{marginTop: 10, width: 150}} size={"small"} danger>Ubah Warna</Button>
                                                </Link>
                                            </Col>
                                            <Col span={8}>
                                                <Image style={{width: "50%"}} src={this.state.dataVariantColor?.url_image}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col style={{paddingTop: 20}} xxl={{span: 17}} xl={{span: 17}} lg={{span: 17}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <Row>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <i className={`text-small-grey-nopadding`}>Metode Pembayaran *</i><br/>
                                                <div style={{fontSize: "135%"}}>Pembelian Tunai</div>
                                                <br/>
                                                <div style={{display: "none"}}>
                                                    <Radio.Group onChange={event => this.onFieldChange("purchaseMethod", event)} value={this.state.purchaseMethod}>
                                                        <Radio value={"cash"}>Tunai</Radio>
                                                        <Radio value={"credit"}>Kredit</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}/>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                                                    <Input value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemilik"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nomor KTP *</i>
                                                    <Input
                                                        maxLength={16}
                                                        value={this.state.idCardNumber}
                                                        placeholder="Nomor KTP"
                                                        onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Nomor Telpon *</i>
                                                    <Input
                                                        value={this.state.phoneNumber}
                                                        maxLength={17}
                                                        placeholder="Format: 08xxxxxxxx"
                                                        onBlur={this.phoneNumberBlur}
                                                        onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Email *</i>
                                                    <Input value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)} placeholder="email"/>
                                                </Form.Item>
                                            </Col>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <div className={`card-box-shadow`}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <IdcardOutlined className={`icon-title-info`}/>
                                                            <b className={`text-title-info`}>KTP</b>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col span={23} offset={1}>
                                                            <Row>
                                                                <Col span={12}>
                                                                    <div className="font-old-grey-100">
                                                                        <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                                        <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.fullName} </div>
                                                                    </div>
                                                                </Col>
                                                                <Col span={12}>
                                                                    {this.state.idCardImageUrl
                                                                        ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.idCardImageUrl}/></div>
                                                                        : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'/assets/img/uploadImage.png'}/></div>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                            <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                                {this.state.idCardImageUrl
                                                                    ? <span>Update Gambar KTP</span>
                                                                    : <span>Lengkapi Gambar KTP</span>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <div className={`card-box-shadow`}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <IdcardOutlined className={`icon-title-info`}/>
                                                            <b className={`text-title-info`}>Signature</b>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{paddingTop: 10}}>
                                                        <Col span={23} offset={1}>
                                                            <Row>
                                                                <Col span={12}>
                                                                    <div className="font-old-grey-100">
                                                                        <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                                        <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                                        <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.fullName} </div>
                                                                    </div>
                                                                </Col>
                                                                <Col span={12}>
                                                                    {this.state.signatureImageUrl
                                                                        ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.signatureImageUrl}/></div>
                                                                        : <div onClick={() => this.clickUpdateSignatureImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'/assets/img/uploadImage.png'}/></div>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                            <div onClick={() => this.clickUpdateSignatureImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                                {this.state.signatureImageUrl
                                                                    ? <span>Update Gambar Tanda Tangan</span>
                                                                    : <span>Lengkapi Tanda Tangan</span>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>

                                            <Divider/>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Provinsi Pemilik *</i>
                                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceName={this.state.customerProvinceName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kota Pemilik *</i>
                                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.customerProvinceCode} cityName={this.state.customerCityName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kecamatan Pemilik *</i>
                                                    <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.customerCityCode} districtName={this.state.customerDistrictName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kelurahan Pemilik *</i>
                                                    <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.customerDistrictCode} subdistrictName={this.state.customerSubdistrictName}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kode Pos Pemilik *</i>
                                                    <Input value={this.state.customerPostalCode} onChange={event => this.onFieldChange("customerPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Row>
                                                    <Col span={12} style={{paddingRight: '2px'}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>RT</i>
                                                            <Input maxLength={3} value={this.state.customerHamlet} onChange={event => this.onFieldChange("customerHamlet", event.target.value)} placeholder="RT"/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12} style={{paddingLeft: '2px'}}>
                                                        <Form.Item>
                                                            <i className={`text-small-grey-nopadding`}>RW</i>
                                                            <Input maxLength={3} value={this.state.customerNeighbourhood} onChange={event => this.onFieldChange("customerNeighbourhood", event.target.value)} placeholder="RW"/>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xl={{span: 24}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Alamat Pemilik *</i>
                                                    <TextArea showCount maxLength={200} value={this.state.customerAddress} onChange={event => this.onFieldChange("customerAddress", event.target.value)} placeholder="Alamat"/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Checkbox onChange={e => this.onFieldChange("takeInDealer", e.target.checked)} checked={this.state.takeInDealer}>
                                                        Saya ingin mengambil unit di dealer.
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>

                                            <Divider style={this.state.purchaseMethod === 'credit' ? {width: "100%"} : {display: 'none'}}/>

                                            <Card title="Skema Kredit" size={"small"} style={this.state.purchaseMethod === 'credit' ? {width: "100%"} : {display: 'none'}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Leasing</i><br/>
                                                    <FincoSelect onFincoSelectChangeProps={this.onFincoSelectChange} value={this.state.fincoChosenCode}/>
                                                </Form.Item>
                                                <div style={{marginBottom: 10}}>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 15% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(15)} color="#f50">15%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 20% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(20)} color="#e2c430">20%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 25% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(25)} color="#87d068">25%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 30% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(30)} color="#87d068">30%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 40% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(40)} color="#87d068">40%</Tag>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title="jadikan uang muka 50% dari OTR">
                                                        <Tag style={{cursor: "pointer", paddingLeft: 3, paddingRight: 3}} onClick={() => this.changeDpPercentage(50)} color="#87d068">50%</Tag>
                                                    </Tooltip>
                                                </div>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Uang Muka</i> <i style={{color: "gray", fontSize: '70%'}}>(Kelipatan 1jt)</i>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        groupSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'Rp. '}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.downPayment}
                                                        onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurDownPayment}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Tenor</i><br/>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        max={100}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.tenor}
                                                        onValueChange={value => this.onFieldChange("tenor", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurTenor}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Cicilan</i> <i style={{color: "gray", fontSize: '70%'}}>(Kelipatan 100k)</i>
                                                    <CurrencyInput
                                                        className={'ant-input same-ant-input'}
                                                        groupSeparator={'.'}
                                                        decimalSeparator={','}
                                                        prefix={'Rp. '}
                                                        style={{marginTop: 5, width: "97%"}}
                                                        value={this.state.installmentAmount}
                                                        onValueChange={value => this.onFieldChange("installmentAmount", value ? parseFloat(value) : 0)}
                                                        onBlur={this.blurInstallmentAmount}
                                                    />
                                                </Form.Item>
                                                <Row>
                                                    <Col span={24}>
                                                        <br/><i style={{fontSize: '80%'}}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br/>
                                                    </Col>
                                                </Row>
                                            </Card><br/>

                                            <Divider/>

                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Promo</i>
                                                    <Input value={this.state.usedPromoData?.promoCode} onClick={() => this.setState({modalPromoShow: true})} style={{cursor: "pointer"}} readOnly={true} placeholder="Lebih Hemat Dengan Promo" prefix={<SearchOutlined className={'grey-color'}/>}/>
                                                    <div style={this.state.usedPromoData?.note ? {} : {display: 'none'}}>
                                                        <br/>
                                                        <i><Typography.Text mark>{this.state.usedPromoData?.note}</Typography.Text></i>
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>Kode Agen</i>
                                                    <Input
                                                        suffix={this.state.fetchAgentCodeFromLeadNumber && (<Spin indicator={<LoadingOutlined/>}/>)}
                                                        disabled={this.state.disableAgentCode}
                                                        value={this.state.agentCode}
                                                        onChange={event => this.onFieldChange("agentCode", event.target.value)}
                                                        placeholder="Dapat Dikosongkan"
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Divider/>


                                            {/* PREBOOKING-COMMENT  */}
                                            <div style={{display: "none"}}>
                                                <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kode Booking *</i>
                                                        <Input
                                                            value={this.state.prebookingCode}
                                                            onChange={event => this.onFieldChange("prebookingCode", event.target.value)}
                                                            placeholder="Dapat Dikosongkan"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={{span: 12}} xs={{span: 24}} style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 7}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kode Deposit *</i>
                                                        <Input
                                                            value={this.state.depositCode}
                                                            onChange={event => this.onFieldChange("depositCode", event.target.value)}
                                                            placeholder="Dapat Dikosongkan"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </div>

                                            <Button onClick={() => this.onSubmit()} loading={this.state.submitButton} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}} danger>Submit Data SPK</Button>
                                        </Row>

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div style={{minHeight: 100}}/>
                <FooterSection/>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {background: "#f33232", border: "#f33232", color: "#fff"} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", marginLeft: 25}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{id_card_number: this.state.idCardNumber}}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Data Signature"
                    style={{top: 10}}
                    maskClosable={false}
                    open={this.state.showModalUploadSignature}
                    onCancel={() => this.setState({showModalUploadSignature: false})}
                    footer={[
                        <Button key={'trim'} onClick={() => this.saveSignatureData()} style={{backgroundColor: '#384967', width: "160px", borderColor: '#384967', color: '#fff'}}> Use Canvas Signature </Button>,
                        <Button key={'clear'} type="default" onClick={() => this.clear()}> Clear Canvas </Button>,
                    ]}
                >
                    {/* HIDDEN UPLOAD-SIGNATURE */}
                    <Upload
                        method="POST"
                        action={baseUrlCdnUpload + "/upload-document/document-data"}
                        listType="picture"
                        maxCount={1}
                        data={{
                            document_name: this.state.idCardNumber,
                            document_type: "signature",
                            source: "amartachery.com",
                        }}
                        name="image"
                        onChange={this.uploadSignatureChange}
                    >
                        <Button style={{display: "none"}} icon={<UploadOutlined/>}>Upload Gambar Signature (Tanda Tangan)</Button>
                    </Upload>

                    <div style={{textAlign: "center"}}>
                        *Lakukan tandatangan digital pada canvas dibawah tulisan ini.<br/>
                        <SignatureCanvas penColor='black' canvasProps={{width: 320, height: 200, className: 'sigCanvas'}} ref={(ref) => {
                            this.sigPad = ref
                        }}/>
                    </div>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Promo"
                    maskClosable={false}
                    open={this.state.modalPromoShow}
                    onOk={() => this.setState({modalPromoShow: false, usedPromoData: {}})}
                    onCancel={() => this.setState({modalPromoShow: false, usedPromoData: {}})}
                    footer={null}
                >
                    <ListPromoModal
                        onPromoChangeProps={this.onPromoChange}
                        company={"amarta"}
                        purchaseMethod={"cash"}
                        area={this.state.areaCode}
                        vehicle={this.state.dataVariantColor}
                        alternativeColor={this.state.alternativeColor?.code}
                        amartaVipUid={this.state.amartaVipUid}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(PurchasePlant);
