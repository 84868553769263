import axios from "axios";
import {baseUrlIdealTrimitra, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class LeadService {
    private axios = axios.create({
        baseURL: baseUrlIdealTrimitra,
    });

    public setToken() {
        this.axios.defaults.headers.common['Authorization'] = 'Basic bGVhZHNDbGllbnRBcHA6cER5PEEyVC5zKGYzYDZIWg==';
    }

    public async getLeads(params: { organizationCode: string, agentCode: string }) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/leads/get-lead/${params.organizationCode}/${params.agentCode}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getCounterLeads(params: { organizationCode: string }) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/leads/get-lead/${params.organizationCode}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getGlobalNotes(params: { organizationCode: string, date: string }) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/leads/get-notes/${params.organizationCode}?date=${params.date}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getGlobalNotesByPhoneNumber(params: { organizationCode: string, phoneNumber: string }) {
        const queries = {}
        try {
            return await this.axios.get<any>(`/leads/get-notes/${params.organizationCode}/${params.phoneNumber?.trim()}`, {
                headers: {"X-Api-Key": xApiKeyAmartaVip,},
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateLeadLevel(inputData?: any) {
        try {
            return await this.axios.post<any>('/leads/update-level',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": ""
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

    public async updateLeadFollowUp(inputData?: any) {
        try {
            return await this.axios.post<any>('/leads/update-follow-up-status',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": ""
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data?.error);
        }
    }

}

export const leadService = new LeadService();
