import React, {Component} from "react";
import parse from 'html-react-parser';
import {Button, Card, Divider, Form, message, Modal, Row, Tooltip} from "antd";
import Col from "antd/lib/col";
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import currencyFormat from "../../helper/currencyFormat";

class CardPromo extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Kode Promo Berhasil Dicopy');
    }

    render() {
        return (
            <React.Fragment>
                <Card
                    hoverable
                    style={{width: "100%", cursor: "unset"}}
                    cover={<img alt="vacancy-logo" src={this.props?.data?.url_image}/>}
                >
                    <Row>
                        <Col span={24}>
                            <span className={`card-product-title`}>{this.props?.data?.promo_code ?? ""}</span>
                            <Tooltip title="Click untuk copy Kode Promo" color={'red'}>
                                <CopyOutlined style={{color: "#757575", paddingLeft: 2}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.props?.data?.promo_code)}/>
                            </Tooltip>
                            <br/>
                            <div className={`pt-10`}>
                                <span className={`card-promo-caption`}>
                                    {this.props?.data?.caption?.split('', 80).reduce((o: any, c: any) => o.length === 79 ? `${o}${c}...` : `${o}${c}`, '')}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Button onClick={() => this.setState({showModalDetailPromo: true})} danger block={true}>Lihat Detail</Button>
                </Card>

                <Modal
                    title={"Detail Promo"}
                    open={this.state.showModalDetailPromo}
                    onCancel={() => this.setState({showModalDetailPromo: false})}
                    footer={[
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalDetailPromo: false})}> Close </Button>,
                    ]}
                >
                    <Divider/>
                    <img style={{width: "100%"}} alt="vacancy-logo" src={this.props?.data?.url_image}/>
                    <Form layout="vertical" className={`pt-10`}>
                        <span className={`card-product-title`}>{this.props?.data?.promo_code ?? ""}</span>
                        <Tooltip title="Click untuk copy Kode Promo" color={'red'}>
                            <CopyOutlined style={{color: "#757575", paddingLeft: 2}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.props?.data?.promo_code)}/>
                        </Tooltip>
                        <br/>
                        <span className={`card-promo-caption`}>
                            {this.props?.data?.caption}
                        </span><br/>
                        <div className={`pt-10`}>Discount Promo:</div>
                        <span style={{color: "grey"}}>{(this.props?.data?.discount_type === 'nominal') ? currencyFormat(this.props?.data?.discount_value) : this.props?.data?.discount_value + ' %'}</span><br/>
                        <div className={`pt-10`}>Masa Berlaku:</div>
                        <span style={{color: "grey"}}>{this.props?.data?.start_period?.slice(0, -3)} - {this.props?.data?.end_period?.slice(0, -3)}</span><br/>
                        <div className={`pt-10`}>Ketentuan Promo:</div>
                        <div style={{color: "grey", paddingLeft: 20}}>{parse(this.props?.data?.term_condition ?? "")}</div>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default CardPromo;
