import axios from "axios";

class TestService {
    private axios = axios.create({
        baseURL: "https://2dkeeytpe6.execute-api.ap-southeast-1.amazonaws.com/v1",
    });

    public async getFeeds() {
        const queries = {}
        try {
            return await this.axios.get<any>(`/feed/content-curation?type=default&brand_uid=afce6884-fc4d-4f7a-b784-09d2a2f7b903`, {
                headers: {
                    "X-Api-Key": "4fRnrxNsiE8QPRpc8RTIH80VcpzUg6xL2G2w3Vti",
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const testService = new TestService();