import React from "react";
import "./FooterReasonBuy.css";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const FooterReasonBuy = () => {
    return (
        <div className={`footer-reason-buy`}>
            <div className={`footer-r-title`}>
                Alasan mengapa membeli di Amartachery
            </div>

            <Row className={`frr`}>
                <Col xl={{span: 3}} lg={{span: 3}} md={{span: 5}} sm={{span: 5}} xs={{span: 5}}>
                    <img className={`fr-img`} alt={`imageamarta`} width={30} src={'/assets/img/icon/badge.svg'}/>
                </Col>
                <Col xl={{span: 21}} lg={{span: 21}} md={{span: 19}} sm={{span: 19}} xs={{span: 19}} className={`fr-text`}>100% bukan barang abal-abal</Col>
            </Row>

            <Row className={`frr`}>
                <Col xl={{span: 3}} lg={{span: 3}} md={{span: 5}} sm={{span: 5}} xs={{span: 5}}>
                    <img className={`fr-img`} alt={`imageamarta`} style={{paddingTop:10}} width={30} src={'/assets/img/icon/customerservice.svg'}/>
                </Col>
                <Col xl={{span: 21}} lg={{span: 21}} md={{span: 19}} sm={{span: 19}} xs={{span: 19}} className={`fr-text`}>Tetap terhubung dengan kami dan afterservice memadai</Col>
            </Row>

            <Row className={`frr`}>
                <Col xl={{span: 3}} lg={{span: 3}} md={{span: 5}} sm={{span: 5}} xs={{span: 5}}>
                    <img className={`fr-img`} alt={`imageamarta`} width={30} src={'/assets/img/icon/wallet.svg'}/>
                </Col>
                <Col xl={{span: 21}} lg={{span: 21}} md={{span: 19}} sm={{span: 19}} xs={{span: 19}} className={`fr-text`}>Pembayaran mudah dari kredit hingga tunai</Col>
            </Row>
        </div>
    );
}

export default FooterReasonBuy;
