import Cookies from "universal-cookie";

class GetLocation {
    public async initTrack() {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const cookies = new Cookies();
                let latCookies = cookies.get('_lat') ?? null;
                let lngCookies = cookies.get('_lng') ?? null;
                let ipCookies = cookies.get('_ip') ?? null;
                if (!latCookies || !lngCookies) {
                    cookies.set('_lat', position.coords.latitude, {maxAge: 216000});
                    cookies.set('_lng', position.coords.longitude, {maxAge: 216000});
                }
                if (!ipCookies) {
                    cookies.set('_ip', null, {maxAge: 216000});
                }
            },
            async (err) => {
                // await this.sendTrackLog(params);
            }
        );
    }
}

export const getLocation = new GetLocation();