import React, {Component} from "react";
import Select from "antd/lib/select";
import {b2bServices} from "../../services/b2b/B2bService";

class FincoSelect extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            fincoListData: [],
            fincoSelect: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            fincoSelect: value,
        });
        this.props.onFincoSelectChangeProps(option);
    }

    async componentDidMount() {
        b2bServices.getListLeasing().then(async (value: { data: { data: any; }; }) => {
            const tempFinco = [{
                code: "GLOBAL",
                name: "PENAWARAN TERBAIK"
            }];

            for (const element of value?.data?.data) {
                tempFinco.push({
                    code: element?.code,
                    name: element?.name
                });
            }

            await this.promisedSetState({
                fincoListData: tempFinco
            });
        }).catch(async () => {
            await this.promisedSetState({
                fincoListData: []
            });
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {

        return (
            <Select
                placeholder="Pilih Tenor"
                showSearch
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.fincoSelect}
            >
                {
                    this.state.fincoListData.map((item: any, i: number) =>
                        <Select.Option key={i} value={item.code}>{item.name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default FincoSelect;
