import React, {Component} from 'react';
import {Buffer} from "buffer";
import {Layout, Image, Button, Card, Divider, Form, Input, Row, Drawer} from "antd";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import Cookies from 'universal-cookie';
import {withRouter} from "../../hoc/withRouter";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import {Link} from "react-router-dom";
import {appVersion} from "../../config/appDataConfig/appConfig";
import FooterSection from "../../component/pageSection/FooterSection";

export interface LoginProps {
}

export interface LoginStates extends LoginFields {
    processing: boolean;
    captchaSuccess: boolean,
    openDrawer: boolean,
}

export interface LoginFields {
    userName: string;
    password: string;
    resetEmail: string;
}

class Login extends Component<LoginProps & any, LoginStates> {
    private cookies = new Cookies();
    private readonly initState!: LoginStates;

    constructor(props: LoginProps & any) {
        super(props);

        this.initState = {
            processing: false,
            captchaSuccess: false,
            password: "",
            userName: "",
            openDrawer: false,
            resetEmail: "",
        }

        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof LoginFields>(target: T, value: string) => {
        const currentState: LoginStates = {...this.state};
        switch (target) {
            case "userName":
                currentState.userName = value;
                break;
            case "password":
                currentState.password = value;
                break;
            case "resetEmail":
                currentState.resetEmail = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    resetPassword = async () => {
        const resetEmail = this.state.resetEmail;
        if (!resetEmail) {
            Modal.error({
                title: 'Error',
                content: "Lengkapi data"
            });
            return false;
        }

        this.setState({
            processing: true,
        });

        const dataReset = {
            app_name: "Trimitra Talent",
            redirect_url: "https://talent.trimitra.biz/reset-password",
            media: "email",
            email: this.state.resetEmail
        }

        try {
            await authApiService.sendResetPassword(dataReset);
            this.setState({
                processing: false,
                openDrawer: false,
                resetEmail: ""
            });
            Modal.success({
                title: 'Proses Sukses',
                content: 'silakan cek email anda, dan lakukan instruksi selanjutnya.'
            });
        } catch (e) {
            Modal.error({
                title: 'Reset Password Failed',
                content: e + ' ',
            });
            this.setState({
                processing: false,
            });
        }
    }

    submit = async () => {
        const userName = this.state.userName;
        const password = this.state.password;

        if (!userName || !password) {
            Modal.error({
                title: 'Error',
                content: "Lengkapi data"
            });
            return false;
        }

        this.setState({
            processing: true,
        });

        let dataLogin = {};
        if (Number(userName)) {
            dataLogin = {
                type: "phone",
                phone: userName,
                password: password
            }
        } else {
            dataLogin = {
                type: "email",
                email: userName,
                password: password
            }
        }

        try {
            const login = await authApiService.login(dataLogin);
            this.cookies.set('_tc-t', login.data.data.token);
            this.cookies.set('_tc-n', Buffer.from(login.data.data.name).toString('base64'));
            this.cookies.set('_tc-p', Buffer.from(login.data.data.phone).toString('base64'));
            this.cookies.set('_tc-e', Buffer.from(login.data.data.email).toString('base64'));
            this.cookies.set('_tc-ut', Buffer.from(login.data.data?.user_type ?? "").toString('base64'));
            this.cookies.set('_tc-uc', Buffer.from(login.data.data?.user_code ?? "").toString('base64'));
            this.cookies.set('_tc-ui', Buffer.from(login.data.data?.uid ?? "").toString('base64'));

            await authApiService.setToken(login.data.data.token);
            this.setState({
                processing: false,
            });

            this.props.history.push('/my-profile');
        } catch (e) {
            Modal.error({
                title: 'Login Failed',
                content: 'data credential tidak cocok',
            });

            this.setState({
                processing: false,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '85vh', backgroundColor: "#fff", padding: "5vh"}}>
                            <Card type="inner" style={{width: "100%"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 50}}>
                                    <Image style={{height: 80}} preview={false} src={`/assets/img/favicon.png`}/>
                                </div>
                                <div style={{textAlign: "center", paddingTop: 10, fontSize: "120%", fontWeight: 600}}>Login</div>
                                <Divider/>
                                <Row>
                                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <div className="content-main-form">
                                            <Form>
                                                <Form.Item>
                                                    <Input prefix={<UserOutlined/>} placeholder={'Phone Number / Email'} value={this.state.userName} onChange={event => this.onFieldChange("userName", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Password'} value={this.state.password} onChange={event => this.onFieldChange("password", event.target.value)}/>
                                                </Form.Item>
                                                <div style={{textAlign: "right", fontSize: "80%"}}>
                                                    <span style={{cursor: "pointer"}} onClick={() => this.setState({openDrawer: true, resetEmail: ""})}> Lupa Password ? </span>
                                                </div>
                                                <div style={{margin: '25px 0'}}>
                                                    <Button block={true} onClick={this.submit} loading={this.state.processing} type={'primary'} htmlType={'submit'}> Login</Button>
                                                </div>
                                                <div style={{textAlign: "center", fontSize: "80%", paddingTop: 20}}>
                                                    Belum punya akun?
                                                    <Link to={`/register`}>
                                                        <b style={{cursor: "pointer", paddingLeft: 5, color: "#000"}}>Daftar disini</b>
                                                    </Link>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <Link to={`/`}>
                                    <div style={{textAlign: "center", fontSize: "80%", paddingTop: 30}}>
                                        Kembali ke Home Trimitra Talent
                                    </div>
                                </Link>
                            </Card>
                        </div>

                        <Drawer
                            title="Reset Password"
                            placement="top"
                            onClose={() => this.setState({openDrawer: false, processing: false})}
                            open={this.state.openDrawer}
                            getContainer={false}
                            height={"100vh"}
                        >
                            <div style={{textAlign: "center", paddingTop: 80}}>
                                <b style={{fontSize: "129%"}}>Atur ulang kata sandi.</b> <br/><br/>
                                <i style={{color: "#7a7a7a"}}>Masukan email yang terdaftar,<br/> kami akan mengirimkan kode verifikasi melalui email.</i> <br/><br/><br/>

                                <Row>
                                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <div className="content-main-form">
                                            <Form>
                                                <Form.Item>
                                                    <Input prefix={<MailOutlined/>} placeholder={'Email'} value={this.state.resetEmail} onChange={event => this.onFieldChange("resetEmail", event.target.value)}/>
                                                </Form.Item>
                                                <div style={{margin: '25px 0'}}>
                                                    <Button block={true} onClick={this.resetPassword} loading={this.state.processing} type={'primary'} htmlType={'submit'}> Reset Password</Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Drawer>
                    </Col>
                </Layout>
                <FooterSection version={appVersion}/>

            </React.Fragment>
        );
    }
}

export default withRouter(Login);
