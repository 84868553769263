import React, {Component} from "react";
import Cookies from 'universal-cookie';
import CurrencyInput from "react-currency-input-field";
import {Button, Card, Col, Divider, Empty, Form, Input, Modal, Row, Spin} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Select from "antd/lib/select";
import dayjs from "dayjs";
import DatePicker from "antd/lib/date-picker";
import TextArea from "antd/es/input/TextArea";

class BiodataEducationalBackground extends Component<any, any> {
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);

        this.state = {
            fetching: false,
            submitting: false,
            token: this.cookies.get('_tc-t'),
            dataEducationalBackground: [],
            tempDegree: null,
            tempNameOfDegree: "",
            tempInstitutionName: "",
            tempInstitutionLocation: "",
            tempFieldOfStudy: "",
            tempStartDate: dayjs("2000-01", 'YYYY-MM'),
            tempEndDate: dayjs("2003-01", 'YYYY-MM'),
            tempGpa: 0,
            tempNotes: "",
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            tempNameOfDegree: option?.children,
            tempDegree: value,
        })
    }

    onFieldChange = async <T extends keyof Pick<any, "tempInstitutionName" | "tempInstitutionLocation" | "tempFieldOfStudy" | "tempStartDate" | "tempEndDate" | "tempGpa" | "tempNotes">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "tempInstitutionName":
                currentState.tempInstitutionName = value;
                break;
            case "tempInstitutionLocation":
                currentState.tempInstitutionLocation = value;
                break;
            case "tempFieldOfStudy":
                currentState.tempFieldOfStudy = value;
                break;
            case "tempStartDate":
                if (value) {
                    currentState.tempStartDate = value;
                }
                break;
            case "tempEndDate":
                if (value) {
                    currentState.tempEndDate = value;
                }
                break;
            case "tempGpa":
                currentState.tempGpa = value;
                break;
            case "tempNotes":
                currentState.tempNotes = value;
                break;
        }

        await this.promisedSetState({
            ...currentState,
        });
    }

    addUserSosmed = async (media: string) => {
        this.setState({
            formEducationalBackground: true,
            tempDegree: null,
            tempNameOfDegree: "",
            tempInstitutionName: "",
            tempInstitutionLocation: "",
            tempFieldOfStudy: "",
            tempStartDate: dayjs("2000-01", 'YYYY-MM'),
            tempEndDate: dayjs("2003-01", 'YYYY-MM'),
            tempGpa: 0,
            tempNotes: "",
        })
    }

    confirmAddData = async () => {
        if (!this.state.tempInstitutionName || !this.state.tempDegree) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Mohon lengkapi data'
            });
            return;
        }

        const picked = this.state.dataEducationalBackground.find((o: { degree: string; }) => o.degree === this.state.tempDegree);
        if (picked) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'duplicate riwayat pendidikan, silakan hapus jenjang pendidikan yang sama'
            });
            return;
        }

        const dataEducationalBackground = [...this.state.dataEducationalBackground];
        dataEducationalBackground.push({
            degree: this.state.tempDegree,
            degree_name: this.state.tempNameOfDegree,
            institution_name: this.state.tempInstitutionName,
            institution_location: this.state.tempInstitutionLocation,
            start_date: dayjs(this.state.tempStartDate).format("YYYY-MM-DD"),
            end_date: dayjs(this.state.tempEndDate).format("YYYY-MM-DD"),
            field_of_study: this.state.tempFieldOfStudy,
            gpa: this.state.tempGpa,
            notes: this.state.tempNotes
        });

        await this.promisedSetState({
            formEducationalBackground: false,
            dataEducationalBackground: dataEducationalBackground,
        });

        await this.onUpdateVehicleLicense();
    }

    removeEntryCustom = async (i: number) => {
        const dataEducationalBackground = [...this.state.dataEducationalBackground];
        const newArray = [];
        let x = 0;
        for (const element of dataEducationalBackground) {
            if (i !== x) {
                newArray.push(element);
            }
            x++;
        }
        await this.promisedSetState({
            dataEducationalBackground: newArray
        });

        await this.onUpdateVehicleLicense();
    }

    onUpdateVehicleLicense = async () => {
        if (this.state.dataEducationalBackground.length < 1) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'mohon lengkapi minimal 1 Riwayat Pendidikan'
            });
            return;
        }

        this.setState({
            submitting: true,
        });

        const dataUpdate = {
            type: "update-biodata-educational-background",
            educational_background: this.state.dataEducationalBackground
        }

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate);
            Modal.success({
                title: 'Proses Sukses',
                content: 'data berhasil di update'
            });
            this.setState({
                submitting: false,
            });
        } catch (e) {
            Modal.error({
                title: 'update Failed',
                content: 'error: ' + e,
            });
            this.setState({
                submitting: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({
            fetching: true,
        });

        try {
            await authApiService.setToken(this.state.token);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;

            this.setState({
                fetching: false,
                dataEducationalBackground: (dataBio?.educational_background) ? dataBio?.educational_background : [],
            });
        } catch (e) {
            console.log(e);
            this.setState({
                fetching: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Button loading={this.state.submitting} onClick={event => this.addUserSosmed("twitter")} type="primary" icon={<PlusCircleOutlined/>} style={{marginTop: 20}}>Tambah Riwayat Pendidikan</Button>

                <Divider orientation="left" plain style={{marginTop: 50}}>
                    <span className={`divider-stock-purchase`}>Data Riwayat Pendidikan Anda</span>
                </Divider>

                <div style={this.state.fetching ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>

                <div className={`p-20`} style={!this.state.fetching ? {} : {display: 'none'}}>
                    <div style={this.state.dataEducationalBackground?.length < 1 ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>
                    <div style={this.state.dataEducationalBackground?.length < 1 ? {display: 'none'} : {}}>
                        {this.state.dataEducationalBackground.map((item: any, i: number) =>
                            <Card bordered={true} key={i} bodyStyle={{padding: 7}} style={item?.type === "family_register" ? {display: 'none'} : {}}>
                                <Row>
                                    <Col xl={{span: 22}} lg={{span: 22}} md={{span: 21}} sm={{span: 21}} xs={{span: 21}} style={{fontSize: "85%"}}>
                                        <span style={{paddingLeft: 10, color: "gray"}}>{(item?.degree_name) ? item?.degree_name : item?.degree}</span> <br/>
                                        <span style={{paddingLeft: 10}}>{item?.institution_name}</span> &nbsp; <span style={{paddingLeft: 10}}>{item?.tempFieldOfStudy}</span>
                                    </Col>
                                    <Col xl={{span: 2}} lg={{span: 2}} md={{span: 3}} sm={{span: 3}} xs={{span: 3}}>
                                        <Button loading={this.state.submitting} onClick={event => this.removeEntryCustom(i)} style={{marginTop: 10}} danger type="primary" icon={<DeleteOutlined/>}/>
                                    </Col>
                                </Row>
                            </Card>
                        )}
                    </div>
                </div>

                <Modal
                    open={this.state.formEducationalBackground}
                    title="Input Data Riwayat Pendidikan"
                    onCancel={() => this.setState({formEducationalBackground: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button type={"primary"} key="1" onClick={this.confirmAddData}>
                            Add
                        </Button>,
                        <Button key="2" onClick={() => this.setState({formEducationalBackground: false})}>
                            Close
                        </Button>,
                    ]}
                >
                    <Form>
                        <Divider/>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Jenjang Pendidikan</i>
                            <Select
                                placeholder="Pilih Jenjang Pendidikan"
                                showSearch
                                optionFilterProp="children"
                                onChange={this.onChange}
                                value={this.state.tempDegree}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="NO_SCHOOL">Tidak Sekolah</Select.Option>
                                <Select.Option value="DID_NOT_FINISH_ELEMENTARY_SCHOOL">Tidak Lulus SD</Select.Option>
                                <Select.Option value="GRADUATED_ELEMENTARY_SCHOOL">Lulus SD</Select.Option>
                                <Select.Option value="JUNIOR_HIGH_SCHOOL">Lulus SMP</Select.Option>
                                <Select.Option value="HIGH_SCHOOL">Lulus SMA</Select.Option>
                                <Select.Option value="DIPLOMA_1_2">Lulus D1 / D2</Select.Option>
                                <Select.Option value="DIPLOMA_3">Lulus D3</Select.Option>
                                <Select.Option value="DIPLOMA_4_OR_BACHELOR">Lulus S1 / Sederajat</Select.Option>
                                <Select.Option value="MASTER">Lulus S2</Select.Option>
                                <Select.Option value="DOCTOR">Lulus S3</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Nama Sekolah / Institusi</i>
                            <Input value={this.state.tempInstitutionName} onChange={event => this.onFieldChange("tempInstitutionName", event.target.value)} placeholder="Nama Sekolah / Institusi"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Lokasi Sekolah / Institusi</i>
                            <Input value={this.state.tempInstitutionLocation} onChange={event => this.onFieldChange("tempInstitutionLocation", event.target.value)} placeholder="Kota atau area Lokasi Sekolah / Institusi"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>Fakultas / Jurusan / Bidang Studi </i>
                            <Input value={this.state.tempFieldOfStudy} onChange={event => this.onFieldChange("tempFieldOfStudy", event.target.value)} placeholder="Fakultas / Jurusan / Bidang Studi"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Awal Pendidikan</i>
                            <DatePicker
                                value={this.state.tempStartDate}
                                onChange={event => this.onFieldChange("tempStartDate", event)}
                                format={'YYYY-MM'} style={{width: "100%"}}
                                picker="month"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Akhir Pendidikan</i>
                            <DatePicker
                                value={this.state.tempEndDate}
                                onChange={event => this.onFieldChange("tempEndDate", event)}
                                format={'YYYY-MM'} style={{width: "100%"}}
                                picker="month"/>
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`small-text-grey`}>IPK / Nilai </i>
                            <CurrencyInput
                                className={'ant-input css-dev-only-do-not-override-1i9hnpv'}
                                placeholder={'Harga Minimum'}
                                allowDecimals={false}
                                groupSeparator={'.'}
                                decimalSeparator={','}
                                value={this.state.tempGpa}
                                onValueChange={value => this.onFieldChange("tempGpa", value ? parseFloat(value) : 0)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Catatan</i>
                            <TextArea showCount maxLength={200} value={this.state.tempNotes} onChange={event => this.onFieldChange("tempNotes", event.target.value)} placeholder="Catatan / informasi tambahan"/>
                        </Form.Item>
                    </Form>
                    <Divider/>
                </Modal>
            </React.Fragment>
        )
            ;
    }
}

export default BiodataEducationalBackground;
